import React from 'react';

import {
  Table as AntTable,
  COLORS,
  ConfigProvider,
  SPACING,
  TableProps,
} from '@optii/ui-library';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { DataSource } from '../types';

type Props = {
  dataSource: DataSource[];
  loading: boolean;
};

export function Table({ dataSource, loading }: Props) {
  const { t } = useTranslation(['common']);

  const columns: TableProps['columns'] = [
    {
      dataIndex: 'property',
      key: 'property',
      title: t('common:Property'),
      render(value) {
        return value?.label;
      },
    },
    {
      dataIndex: 'employmentType',
      key: 'employmentType',
      title: t('common:Employment Type'),
      render(value, record, index) {
        return value?.label;
      },
    },
    {
      dataIndex: 'positions',
      key: 'positions',
      title: t('common:Positions'),
      onCell: () => ({
        style: {
          paddingInline: SPACING.NONE,
          borderLeft: '1px solid #f0f0f0',
        },
      }),
      render(innerSource) {
        const innerColumns: TableProps['columns'] = [
          {
            dataIndex: 'department',
            key: 'department',
            title: t('common:Department'),
            render(value) {
              return value?.label;
            },
          },
          {
            dataIndex: 'role',
            key: 'role',
            title: t('common:Role'),
            render(value) {
              return value?.label;
            },
          },
        ];

        return (
          <ConfigProvider
            theme={{
              components: {
                Table: {
                  margin: SPACING.NONE,
                  headerBorderRadius: SPACING.NONE,
                },
              },
            }}
          >
            <AntTable
              className="userDetailsTable"
              columns={innerColumns}
              rowKey={(row) => `${row.role?.value}-${row.department?.value}`}
              dataSource={innerSource}
            />
          </ConfigProvider>
        );
      },
    },
  ];

  return (
    <AntTable
      columns={columns}
      rowKey={(row) => row.id}
      loading={loading}
      style={{
        border: `1px solid ${COLORS.neutral[2]}`,
      }}
      dataSource={dataSource}
      locale={{
        emptyText: t('common:No employment details were found for this user.'),
      }}
    />
  );
}
