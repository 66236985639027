import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { TreeSelectLocations } from '@optii-solutions/ui23-antd-components';
import { DownOutlined } from '@ant-design/icons';
import { isPlatform } from '@ionic/react';
import { useQuery } from '@apollo/client';
import {
  COLORS,
  FormItem,
  SPACING,
  Select,
  TextArea,
  TimePicker,
  Checkbox,
  ConfigProvider,
  Flex,
  Typography,
  InputNumber,
  useWatch,
  Button,
  FONTS,
  Row,
} from '@optii-solutions/ui-library';
import { useTranslation } from 'react-i18next';

import {
  GA_EVENTS,
  initGQLData,
  useAccess,
  UserAccessContext,
  type TUploadHandleFile,
  Session,
  useProductAccess,
} from '@optii/shared';
import { PERMISSIONS } from '@optii/shared/constants/permissions';
import { CHECKLIST_TEMPLATES } from '@optii/shared/queries/checklist/checklistTemplates';
import { UploadDragger } from '@optii/shared/components/molecules/UploadDragger';
import { UploadList } from '@optii/shared/components/molecules/UploadList';
import { use12Hours } from '@optii/shared/utils/localizeDateConfiguration';
import GoogleAnalyticsClient from '@optii/shared/utils/GoogleAnalyticsClient';

import { useHousekeepingJobDurationMutation } from '@optii/jobs/api/housekeepingJobDuration';
import { LocationMinimal } from '@optii/jobs/api/jobs';
import { PRODUCT_ACCESS } from '@optii/shared/constants/productAccess';
import {
  CustomStyles,
  JobFormMultipleChecklist,
  STYLES,
} from './JobForm.elements';
import type {
  THousekeepingJobData,
  TJobDeparmentOption,
  TJobRoleOption,
  THousekeepingJobLocationOption,
  THousekeepingJobDataLocation,
  TJobChecklistOption,
  THousekeepingJobFormRegularFieldsProps,
  TPreferredLanguage,
} from './JobForm.types';
import {
  LOCATION_TYPES_NAMES,
  JOB_FORM_DROPDOWN_CUSTOM_HEIGHT,
  JOB_ADVANCED_STATUSES,
  JOB_STATUSES,
} from './JobForm.constants';
import { useJobForm } from './JobForm.hooks';
import {
  EditJobFormTabs,
  TEditJobFormTabsPropsJobChecklists,
  TEditJobFormTabsPropsJobNotes,
} from './JobFormTabs';
import { JobFormDropdownCustomHeightOnFocus } from './JobForm.helpers';

export function JobFormHousekeepingFields(
  props: THousekeepingJobFormRegularFieldsProps,
) {
  const {
    form,
    formOptions,
    showAdvancedFields,
    setShowAdvancedFields,
    mode,
    refetch,
    values,
    audit,
    onClose,
    propertyShard,
  } = props;

  const { globalSnack } = useContext(Session);

  const [notes, setNotes] = useState<string>('');

  const { t } = useTranslation(['common', 'fields', 'jobs', 'projects'], {
    useSuspense: false,
  });
  const { can } = useAccess();

  const canAddChecklist = can(PERMISSIONS.settings.checklists.view);

  const { user } = useContext(UserAccessContext.Context) as TPreferredLanguage;
  const {
    roles,
    departments,
    locations,
    loadingMinimalLocations,
    submitted,
    updateChecklistTask: [updateChecklistTask],
    updateLoading,
    updateJob,
    roomStatus,
    getRoomsData,
  } = useJobForm({
    onClose,
    form,
    propertyShard,
  });

  const { canProduct } = useProductAccess();

  const hasAccessToDurationModel = canProduct([
    PRODUCT_ACCESS.smart_operations,
  ]);

  const [selectedLocations, setSelectedLocations] =
    useState<THousekeepingJobDataLocation[]>();

  const { data: checklistsData } = useQuery(CHECKLIST_TEMPLATES, {
    context: { _instance: 'node', _shard: propertyShard },
    variables: {
      orderBy: 'name_ASC',
    },
    skip: !canAddChecklist,
  });

  const [getHousekeepingJobDuration] = useHousekeepingJobDurationMutation({
    onError(error) {
      console.error(error);
    },
    context: {
      _shard: propertyShard,
      _instance: 'node',
    },
    onCompleted({ housekeepingJobDuration }) {
      if (housekeepingJobDuration) {
        form.setFieldValue(
          'durationMinutes',
          Math.round(housekeepingJobDuration.duration),
        );
      }
    },
  });

  const watchedValues = useWatch([], form);
  const location = watchedValues?.location;
  const action = watchedValues?.actionType;

  const isStatusInProgress = values?.status === JOB_STATUSES.inProgress.value;
  const isAssigneeDisabled = values?.assignee !== null && isStatusInProgress;

  useEffect(() => {
    if (hasAccessToDurationModel) {
      const isFieldsTouched =
        form.isFieldTouched('actionType') || form.isFieldTouched('location');
      if (action && location && isFieldsTouched) {
        const [selectedLocationId] = location;
        const locationData = locations?.find(
          (item: LocationMinimal) =>
            item.id === selectedLocationId?.id || selectedLocationId,
        );

        if (locationData) {
          getHousekeepingJobDuration({
            variables: {
              jobDurationInput: {
                locationId: Number(locationData.id),
                locationName: locationData.longDisplayName as string,
                action,
                roomTypeName: locationData.roomTypeName,
                roomTypeId: locationData.roomTypeId
                  ? Number(locationData.roomTypeId)
                  : undefined,
              },
            },
          });
        }
      }
    }
    // DO NOT ADD LOCATIONS AS A DEPENDENCY IT LOOPS THE USEEFFECT
  }, [
    action,
    location,
    getHousekeepingJobDuration,
    hasAccessToDurationModel,
    form,
  ]);

  // Getting defaults for housekeeping job requires a job item call
  // Housekeeping Job and this is a constant in the Backend

  formOptions.locations =
    locations?.length &&
    locations.map((locationItem: THousekeepingJobLocationOption) => {
      const {
        id,
        pId,
        name,
        longDisplayName,
        shortDisplayName,
        locationTypeId,
        locationTypeName,
        roomTypeId,
        roomTypeName,
      } = locationItem;

      const extra = {
        id,
        pId,
        name,
        longDisplayName,
        shortDisplayName,
        locationTypeId,
        locationTypeName,
        roomTypeId,
        roomTypeName,
      };

      return {
        label: longDisplayName,
        value: id,
        extra,
      };
    });

  formOptions.department = departments.map(
    (departmentItem: TJobDeparmentOption) => {
      const { id, displayName, departmentCode } = departmentItem;

      const extra = {
        id,
        displayName,
        departmentCode,
      };

      return {
        label: displayName,
        value: id,
        extra,
      };
    },
  );

  formOptions.role = roles.map((roleItem: TJobRoleOption) => {
    const { id, name, description } = roleItem;

    const extra = {
      id,
      name,
      description,
    };

    return {
      label: name,
      value: id,
      extra,
    };
  });

  formOptions.checklist = initGQLData(checklistsData).map(
    (checklistItem: TJobChecklistOption) => {
      const { id: value, name: label } = checklistItem;

      return {
        label,
        value: String(value),
      };
    },
  );

  const customStyles = CustomStyles();

  const mappedLocationsWithDisabledParent = locations?.map(
    (locationItem: { childCount: number; id: string | number }) => ({
      ...locationItem,
      selectable: !(
        locationItem.childCount > 0 &&
        String(locationItem.id).indexOf('.1') > -1
      ),
    }),
  );

  async function handleAddNote() {
    try {
      await updateJob({
        variables: {
          id: values.id,
          input: {
            action: form.getFieldValue('actionType'),
            status: values.status,
            notes: [
              {
                text: notes,
              },
            ],
          },
        },
      });

      setNotes('');

      refetch();

      GoogleAnalyticsClient.event(GA_EVENTS.addNoteJob);
    } catch (err) {
      console.error("Couldn't add note!", err);

      globalSnack({
        message: t('jobs:There was a problem adding your note'),
        timeout: 5000,
        error: true,
      });
    }
  }

  const attachments = watchedValues?.attachments;

  const hasPreviousAssignee = values?.assignee;

  return (
    <>
      <FormItem<THousekeepingJobData>
        name="location"
        label={t('fields:Location')}
        rules={[
          {
            required: true,
            message: t('jobs:Required'),
          },
        ]}
        validateTrigger="onBlur"
        normalize={(value: string) => [value]}
      >
        <TreeSelectLocations
          treeData={mappedLocationsWithDisabledParent}
          suffixIcon={<DownOutlined />}
          size="large"
          notFoundContent
          multiple={false}
          loading={loadingMinimalLocations}
          placeholder={t('fields:Search...')}
          showSearch
          placement={isPlatform('capacitor') ? 'topLeft' : undefined}
          setSelectedItems={(item: THousekeepingJobDataLocation) => {
            if (item && item.id) {
              setSelectedLocations([item]);
              form.setFieldValue('location', [item.id]);

              getRoomsData({
                variables: {
                  id: item.id,
                },
              });
            }

            if (item && mode !== 'edit') {
              setShowAdvancedFields(true);
            }
          }}
          treeDefaultExpandedKeys={[
            locations?.find(
              (item: THousekeepingJobDataLocation) =>
                item.locationTypeName === LOCATION_TYPES_NAMES.property,
            )?.id,
          ]}
          selectedItems={selectedLocations || []}
        />
        {roomStatus?.status &&
          selectedLocations &&
          selectedLocations[0] &&
          selectedLocations[0]?.locationTypeName === 'Room' && (
            <Row>
              <Typography.Paragraph
                style={{
                  color: COLORS.neutral[7],
                  margin: SPACING.NONE,
                }}
              >
                {roomStatus?.status} - {selectedLocations[0]?.roomType}
              </Typography.Paragraph>
            </Row>
          )}
      </FormItem>

      {showAdvancedFields || mode === 'edit' ? (
        <>
          <FormItem<THousekeepingJobData>
            name="creditValue"
            label={t('fields:Credit Value')}
          >
            <InputNumber
              size="large"
              style={{
                minWidth: '100%',
                maxWidth: '100%',
              }}
              min={0}
            />
          </FormItem>
          <FormItem<THousekeepingJobData>
            name="durationMinutes"
            label={t('fields:Duration in Minutes')}
          >
            <InputNumber
              size="large"
              style={{
                minWidth: '100%',
                maxWidth: '100%',
              }}
              min={0}
              max={1440}
              precision={0}
            />
          </FormItem>
        </>
      ) : null}

      <FormItem<THousekeepingJobData>
        style={{
          margin: SPACING.NONE,
          marginBottom: SPACING.SIZE_DEFAULT,
        }}
      >
        <ConfigProvider
          theme={{
            components: {
              Select: {
                selectorBg: COLORS.secondary[4],
                colorText: COLORS.neutral[1],
                optionSelectedColor: COLORS.neutral[7],
              },
            },
          }}
        >
          <span
            style={{
              background: COLORS.secondary[4],
              borderRadius: SPACING.SIZE_XS,
              width: 'auto',
              color: COLORS.neutral[1],
              padding: SPACING.SIZE_DEFAULT,
            }}
          >
            {t('fields:Time Frame')}
          </span>
        </ConfigProvider>
      </FormItem>

      <div style={STYLES.timeWindowContainer}>
        <Flex
          style={{
            flexDirection: 'row',
            alignItems: 'stretch',
          }}
        >
          <FormItem<THousekeepingJobData>
            name="timeWindowStart"
            label={t('fields:Start and End Time')}
            style={{
              flex: 1,
            }}
          >
            <TimePicker
              allowClear
              locale={user.preferredLang}
              format={use12Hours() ? 'h:mm a' : 'HH:mm'}
              style={customStyles.TimePicker}
              placeholder={t('fields:Start time')}
            />
          </FormItem>
          <FormItem<THousekeepingJobData>
            name="timeWindowEnd"
            style={{
              flex: 1,
            }}
          >
            <TimePicker
              allowClear
              locale={user.preferredLang}
              format={use12Hours() ? 'h:mm a' : 'HH:mm'}
              style={{
                ...customStyles.TimePicker,
                marginTop: SPACING.SIZE_XL,
                paddingBottom: 2,
              }}
              placeholder={t('fields:End time')}
            />
          </FormItem>
        </Flex>
        <Flex>
          <FormItem<THousekeepingJobData>
            name="rush"
            style={{ fontWeight: 500 }}
            valuePropName="checked"
          >
            <Checkbox>{t('fields:Mark as Rush')}</Checkbox>
          </FormItem>
        </Flex>
      </div>

      <Typography.Title
        level={4}
        style={{
          marginTop: SPACING.SIZE_XL,
          marginBottom: SPACING.SIZE_MS,
          fontFamily: 'Montserrat',
        }}
      >
        {t('jobs:Assignment')}
      </Typography.Title>

      <FormItem<THousekeepingJobData>
        name="department"
        label={t('fields:Department')}
        rules={[
          {
            required: true,
            message: t('jobs:Required'),
          },
        ]}
        validateTrigger="onBlur"
        normalize={(value) => {
          const { extra } = formOptions.department?.find(
            (item) => item.value === value,
          ) || {
            extra: { departmentCode: undefined, displayName: undefined },
          };

          const { displayName, departmentCode } = extra;

          return {
            id: value,
            value,
            displayName,
            departmentCode,
          };
        }}
      >
        <Select
          allowClear
          placeholder={t('fields:Select...')}
          size="large"
          options={formOptions.department}
          optionFilterProp="label"
          showSearch
          placement={isPlatform('capacitor') ? 'topLeft' : undefined}
          listHeight={JOB_FORM_DROPDOWN_CUSTOM_HEIGHT}
          onFocus={() =>
            JobFormDropdownCustomHeightOnFocus({
              height: JOB_FORM_DROPDOWN_CUSTOM_HEIGHT,
            })
          }
        />
      </FormItem>

      <FormItem<THousekeepingJobData>
        name="role"
        label={t('fields:Role')}
        normalize={(value) => {
          const { extra } = formOptions.role?.find(
            (item) => item.value === value,
          ) || {
            extra: { name: undefined, description: undefined },
          };

          const { name, description } = extra;

          return {
            id: value,
            value,
            name,
            description,
          };
        }}
      >
        <Select
          allowClear
          placeholder={t('fields:Select...')}
          size="large"
          options={formOptions.role}
          optionFilterProp="label"
          showSearch
          placement={isPlatform('capacitor') ? 'topLeft' : undefined}
          listHeight={JOB_FORM_DROPDOWN_CUSTOM_HEIGHT}
          onFocus={() =>
            JobFormDropdownCustomHeightOnFocus({
              height: JOB_FORM_DROPDOWN_CUSTOM_HEIGHT,
            })
          }
        />
      </FormItem>

      <FormItem<THousekeepingJobData>
        name="assignee"
        label={t('fields:Team Member')}
        normalize={(value) => {
          const { extra } = formOptions.assignee?.find(
            (item) => item.value === value,
          ) || {
            extra: {
              userName: undefined,
              firstName: undefined,
              lastName: undefined,
            },
          };

          const { userName, firstName, lastName } = extra;

          return {
            id: value,
            value,
            userName,
            firstName,
            lastName,
          };
        }}
        validateStatus={
          mode === 'edit' &&
          hasPreviousAssignee &&
          watchedValues &&
          watchedValues.checklist &&
          watchedValues.checklist.length &&
          !watchedValues.assignee
            ? 'error'
            : 'success'
        }
      >
        <Select
          allowClear
          placeholder={t('fields:Select...')}
          size="large"
          disabled={isAssigneeDisabled}
          options={formOptions.assignee?.filter(
            (employeeItem) => !!employeeItem,
          )}
          optionFilterProp="label"
          showSearch
          placement={isPlatform('capacitor') ? 'topLeft' : undefined}
          listHeight={JOB_FORM_DROPDOWN_CUSTOM_HEIGHT}
          onFocus={() =>
            JobFormDropdownCustomHeightOnFocus({
              height: JOB_FORM_DROPDOWN_CUSTOM_HEIGHT,
            })
          }
        />
      </FormItem>

      <Typography.Title
        level={4}
        style={{
          marginTop: SPACING.SIZE_XL,
          marginBottom: SPACING.SIZE_MS,
          fontFamily: 'Montserrat',
        }}
      >
        {t('jobs:Notes and Attachments')}
      </Typography.Title>

      {mode === 'edit' ? (
        <>
          <JobFormMultipleChecklist
            t={t}
            options={formOptions.checklist}
            selectedOptions={watchedValues?.checklist}
          />

          <FormItem<THousekeepingJobData>
            noStyle
            style={{
              marginBottom: SPACING.SIZE_XL,
            }}
          >
            <EditJobFormTabs
              updateChecklistTask={updateChecklistTask}
              submitted={submitted}
              refetch={refetch}
              hideAddNote
              job={{
                notes: values.notes as TEditJobFormTabsPropsJobNotes[],
                checklists:
                  values.checklist as TEditJobFormTabsPropsJobChecklists[],
                status: values.status,
              }}
              audit={audit}
            />
          </FormItem>

          <Flex
            vertical
            gap={SPACING.SIZE_XS}
            style={{
              marginBottom: SPACING.SIZE_XL,
            }}
          >
            <label htmlFor="notes">
              <span
                style={{
                  minWidth: '100%',
                  fontWeight: 500,
                  fontSize: `${FONTS.xSmall.size}px`,
                }}
              >
                {t('fields:Notes')}
              </span>
            </label>

            <Flex gap={SPACING.SIZE_MD} align="center">
              <TextArea
                id="notes"
                rows={1}
                size="large"
                value={notes}
                onChange={(event: SyntheticEvent<HTMLTextAreaElement>) => {
                  setNotes(event.currentTarget.value);
                }}
              />

              <Button
                type="default"
                disabled={!notes.trim() || updateLoading}
                onClick={async () => {
                  await handleAddNote();
                }}
              >
                {t('jobs:Add Note')}
              </Button>
            </Flex>
          </Flex>
        </>
      ) : (
        <>
          <JobFormMultipleChecklist
            t={t}
            options={formOptions.checklist}
            selectedOptions={watchedValues?.checklist}
          />

          <FormItem<THousekeepingJobData>
            name="notes"
            label={t('fields:Notes')}
          >
            <TextArea rows={2} />
          </FormItem>
        </>
      )}

      {mode === 'edit' && attachments?.length ? (
        <FormItem<THousekeepingJobData>
          name="attachments"
          shouldUpdate={(prevValues, currentValues) =>
            JSON.stringify(prevValues.attachments) !==
            JSON.stringify(currentValues.attachments)
          }
          style={{
            marginBottom: SPACING.NONE,
          }}
        >
          <UploadList
            placeholder={t(
              'fields:Click or drag a file to this area to upload',
            )}
            handleUpload={(file: TUploadHandleFile) => {
              const previousFiles = form.getFieldValue('attachments') || [];

              form.setFieldValue('attachments', [...previousFiles, file]);
            }}
            handleRemove={(file: TUploadHandleFile) => {
              const previous = form.getFieldValue('attachments') || [];

              const next = previous.filter(
                (previousItem: { uid: string; name: string }) =>
                  previousItem.uid !== file.uid,
              );

              form.setFieldValue('attachments', next);
            }}
            analytics={GA_EVENTS.addFile}
            fileList={watchedValues?.attachments.map(
              (attachmentItem: TUploadHandleFile) => attachmentItem.uid,
            )}
          />
        </FormItem>
      ) : (
        <FormItem<THousekeepingJobData>
          name="attachments"
          style={{
            marginBottom: SPACING.NONE,
          }}
        >
          <UploadDragger
            placeholder={t(
              'fields:Click or drag a file to this area to upload',
            )}
            handleUpload={(file: TUploadHandleFile) => {
              const previous = form.getFieldValue('attachments') || [];

              form.setFieldValue('attachments', [...previous, file]);
            }}
            handleRemove={(file: TUploadHandleFile) => {
              const previous = form.getFieldValue('attachments') || [];
              const next = previous.filter(
                (previousItem: { uid: string }) =>
                  previousItem.uid !== file.uid,
              );

              form.setFieldValue('attachments', next);
            }}
            analytics={GA_EVENTS.addFile}
          />
        </FormItem>
      )}
    </>
  );
}
