import { DeleteOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  COLORS,
  ConfigProvider,
  Divider,
  Drawer,
  Flex,
  FONTS,
  Form,
  FormItem,
  FormList,
  Input,
  Row,
  Select,
  SPACING,
  Typography,
  useForm,
  useWatch,
} from '@optii/ui-library';
import React, {
  CSSProperties,
  Dispatch,
  FC,
  Fragment,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  InvitationInput,
  SelectOption,
  useCreateBulkInvitationMutation,
  useUpdateInviteMutation,
  useValidateUserEmailLazyQuery,
} from '@optii/global';
import { Session, UserAccessContext } from '@optii/shared';
import PhoneInput from 'react-phone-input-2';

import { SECTION_TITLE_STYLE } from '../utils';
import { Positions } from './Positions';
import { Footer } from './Footer';
import Title from './Title';
import { FormValues } from './type';
import { DataSource, UserInfo } from '../types';

type PhoneInputProps = {
  id?: string;
  value?: string;
  onChange?: (value: string) => void;
};

type InviteVariables = {
  payload: InvitationInput;
  shard: string;
};

const HELP_TEXT_STYLE: CSSProperties = {
  color: COLORS.polarGreen[7],
  fontSize: FONTS.xSmall.size,
  letterSpacing: FONTS.xSmall.letterSpacing,
};

const CustomPhoneInput: FC<PhoneInputProps> = (props) => {
  const { value, onChange, id } = props;

  return (
    <span id={id}>
      <PhoneInput
        country="us"
        inputStyle={{
          height: 32,
        }}
        inputProps={{
          id,
        }}
        prefix="+"
        value={value}
        onChange={(_, __, ___, formattedValue) => {
          if (onChange) {
            onChange(formattedValue);
          }
        }}
      />
    </span>
  );
};

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  properties: SelectOption[];
  userInfo?: UserInfo;
  employmentInfo?: DataSource[];
  edit?: boolean;
};

export function InviteUserForm({
  open,
  setOpen,
  properties,
  employmentInfo,
  userInfo,
  edit = false,
}: Props) {
  const { t } = useTranslation(['common']);
  const { globalSnack } = useContext(Session);
  const { user } = useContext(UserAccessContext.Context);
  const [sendNow, setSendNow] = useState(true);
  const [emailExists, setEmailExists] = useState(false);
  const [form] = useForm();

  const existingPropertyIds = employmentInfo?.map((item) => ({
    id: item.property?.value,
    inviteCode: item.inviteCode,
  }));

  const selectedProperties = useWatch(['properties'], form);

  const [validateEmail] = useValidateUserEmailLazyQuery({
    onError(error) {
      console.error(error);
    },
  });

  useEffect(() => {
    if (edit && userInfo && employmentInfo) {
      form.setFieldsValue({
        properties: employmentInfo,
        userPhone: userInfo.userMobileNumber,
        userEmail: userInfo.userEmail,
        userFirstName: userInfo.userFirstName,
        userLastName: userInfo.userLastName,
      });
    }
  }, [edit, userInfo, employmentInfo, form]);

  const [updateInvite, { loading: updateInviteLoading }] =
    useUpdateInviteMutation({
      onError(error) {
        console.error(error);
      },
    });

  const [createBulkInvitation, { loading }] = useCreateBulkInvitationMutation({
    onError(error) {
      console.error(error);
    },
  });
  const onClose = () => {
    setOpen(false);
  };

  const accessibleProperties = properties.sort((a, b) =>
    a.label.localeCompare(b.label),
  );

  function onCancel() {
    setOpen(false);
  }

  const validateUserEmail = useCallback(
    async (email: string) => {
      const { data } = await validateEmail({
        variables: {
          email,
        },
      });

      if (data?.userEmail.user) {
        setEmailExists(true);
        return Promise.resolve();
      }
      setEmailExists(false);
      return Promise.resolve();
    },
    [validateEmail],
  );

  const onCreateInvitations = useCallback(
    async ({ payload, shard }: InviteVariables) => {
      const response = await createBulkInvitation({
        variables: {
          createBulkInvitationInput: {
            invitations: [payload],
          },
        },
        context: {
          _shard: shard,
          _instance: 'node',
        },
      });

      return {
        response: response.data,
        orgName: payload.OrgName,
      };
    },
    [createBulkInvitation],
  );
  const onEditInvitations = useCallback(
    async ({
      payload,
      shard,
      inviteCode,
    }: InviteVariables & { inviteCode: string }) => {
      const input = {
        ...payload,
        roles: payload.roles?.map((item) => ({
          ...item,
          deptID: Number(item?.deptId),
          roleID: Number(item?.roleId),
          deptName: String(item?.deptName),
          roleName: String(item?.roleName),
        })),
      };
      const response = await updateInvite({
        variables: {
          inviteCode,
          input,
        },
        context: {
          _shard: shard,
        },
      });

      return {
        response: response.data,
        orgName: payload.OrgName,
      };
    },
    [updateInvite],
  );

  async function onFinish({
    userEmail,
    userPhone,
    properties: selectedPropertyData,
    userFirstName,
    userLastName,
  }: FormValues) {
    await Promise.all(
      selectedPropertyData.map(async (propertyDetails) => {
        const shard = propertyDetails.property.value;
        const roles = propertyDetails.positions.map((position) => ({
          deptId: Number(position.department.value),
          deptName: position.department.label,
          roleId: Number(position.role.value),
          roleName: position.role.label,
        }));

        const payload = {
          userEmail,
          userPhone,
          userFirstName,
          userLastName,
          sendNow,
          sendText: false,
          userExists: emailExists,
          userId: Number(user?.id),
          orgId: Number(propertyDetails.property.value),
          OrgName: propertyDetails.property.label,
          employmentTypeId: Number(propertyDetails.employmentType.value),
          employmentTypeName: propertyDetails.employmentType.label,
          roles,
        };

        const existingPropertyInvite = existingPropertyIds?.find(
          (item) => item.id === propertyDetails.property.value,
        );
        if (existingPropertyInvite?.id && existingPropertyInvite.inviteCode) {
          return onEditInvitations({
            payload,
            shard,
            inviteCode: existingPropertyInvite.inviteCode,
          });
        }
        return onCreateInvitations({ payload, shard });
      }),
    )
      .then((res) => {
        const failedInvites: string[] = [];

        res.map(({ response, orgName }) => {
          if (response && orgName) {
            if ('createBulkInvitation' in response) {
              if (response?.createBulkInvitation?.invitations_error?.length) {
                failedInvites.push(orgName);
              }
            }

            return {
              response,
              orgName,
            };
          }
          return undefined;
        });

        if (failedInvites.length) {
          globalSnack({
            message: t(
              'common:This e-mail is already associated with an employee in the following properties: {{properties}}',
              {
                properties: failedInvites.join(', '),
              },
            ),
            error: true,
            timeout: 6000,
          });
        } else
          globalSnack({
            message: edit
              ? t('common:Invites Updated')
              : t('common:Invites sent successfully.'),
            timeout: 6000,
          });

        onClose();
      })
      .catch((error) => {
        console.error(error);
        globalSnack({
          message: t(
            'common:An error has occurred when attempting to send the invites',
          ),
          error: true,
          timeout: 5000,
        });
      });
  }

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            colorTextPlaceholder: COLORS.neutral[5],
          },
        },
      }}
    >
      <Drawer
        open={open}
        width={675}
        onClose={onClose}
        destroyOnClose
        footer={
          <Footer
            setSendNow={setSendNow}
            onCancel={onCancel}
            form={form}
            edit={edit}
            loading={loading || updateInviteLoading}
          />
        }
        title={<Title edit={edit} onClose={onClose} />}
      >
        <Form
          form={form}
          name="addTeamMember"
          onFinish={onFinish}
          onValuesChange={(_, v) => console.log(v)}
          scrollToFirstError={{
            behavior: 'smooth',
          }}
          initialValues={{
            properties: [
              {
                positions: [{}],
              },
            ],
          }}
        >
          <Typography.Paragraph style={SECTION_TITLE_STYLE}>
            Name and Contact Details
          </Typography.Paragraph>

          <Row align="bottom" gutter={SPACING.SIZE_MD}>
            <Col xs={24} sm={24} md={12}>
              <FormItem
                label={t('common:First Name')}
                name="userFirstName"
                required
                rules={[
                  {
                    required: true,
                    message: 'This is a required field',
                  },
                ]}
              >
                <Input />
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <FormItem
                label={t('common:Last Name')}
                name="userLastName"
                required
                rules={[
                  {
                    required: true,
                    message: 'This is a required field',
                  },
                ]}
              >
                <Input />
              </FormItem>
            </Col>
          </Row>
          <Row
            align="top"
            gutter={SPACING.SIZE_MD}
            style={{
              marginBottom: SPACING.SIZE_SM,
            }}
          >
            <Col xs={24} sm={24} md={12}>
              <FormItem
                name="userEmail"
                required
                label={t('common:Email Address')}
                dependencies={['properties']}
                help={
                  emailExists ? (
                    <Typography.Text style={HELP_TEXT_STYLE}>
                      {t(
                        'profile:Good News! This person already has a user profile with Optii and we will send them an invite.',
                      )}
                    </Typography.Text>
                  ) : null
                }
                validateDebounce={300}
                rules={[
                  {
                    type: 'email',
                    message: 'Invalid email format',
                  },
                  {
                    async validator(rule, value) {
                      return validateUserEmail(value);
                    },
                  },
                  {
                    required: true,
                    message: 'This is a required field.',
                  },
                ]}
              >
                <Input />
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <FormItem
                name="userPhone"
                required
                label={t('common:Mobile Number')}
              >
                <CustomPhoneInput />
              </FormItem>
            </Col>
          </Row>

          <Typography.Paragraph style={SECTION_TITLE_STYLE}>
            Employment Details
          </Typography.Paragraph>
          <FormList name="properties">
            {(propertyFields, { add, remove }) => (
              <>
                {propertyFields.map((propertyField) => (
                  <Fragment key={propertyField.key}>
                    <Flex align="start" gap={SPACING.SIZE_MD}>
                      <FormItem
                        name={[propertyField.name, 'property']}
                        required
                        normalize={(v) => ({
                          label: accessibleProperties.find(
                            (item) => v === item.value,
                          )?.label,
                          value: v,
                        })}
                        getValueProps={(valueProps) => ({
                          value: valueProps?.value,
                        })}
                        style={{
                          width: '100%',
                        }}
                        rules={[
                          {
                            required: true,
                            message: t('common:Property is a required field.'),
                          },
                        ]}
                      >
                        <Select
                          placeholder={t('common:Property')}
                          showSearch
                          disabled={existingPropertyIds?.includes(
                            form.getFieldValue([
                              'properties',
                              propertyField.key,
                              'property',
                            ])?.value,
                          )}
                          optionFilterProp="label"
                          options={accessibleProperties.map((property) => {
                            const propertyIds =
                              selectedProperties?.map(
                                (item: { property: { value: string } }) =>
                                  item?.property?.value,
                              ) || [];

                            return {
                              disabled: propertyIds?.includes(property.value),
                              ...property,
                            };
                          })}
                        />
                      </FormItem>
                      <Button
                        icon={<DeleteOutlined />}
                        onClick={() => remove(propertyField.name)}
                        htmlType="button"
                        type="text"
                        styles={{
                          icon: {
                            color: COLORS.neutral[6],
                          },
                        }}
                        disabled={propertyFields.length === 1}
                      />
                    </Flex>
                    <FormItem
                      noStyle
                      shouldUpdate={(prev, curr) =>
                        prev.properties[propertyField.name]?.property !==
                        curr.properties[propertyField.name]?.property
                      }
                    >
                      {({ getFieldValue }) =>
                        getFieldValue([
                          'properties',
                          propertyField.name,
                          'property',
                        ]) ? (
                          <Positions
                            propertyPath={propertyField.name}
                            form={form}
                          />
                        ) : null
                      }
                    </FormItem>
                    <Divider />
                  </Fragment>
                ))}
                <Button
                  type="primary"
                  block
                  onClick={() =>
                    add({
                      positions: [{}],
                    })
                  }
                >
                  {t('common:Add Additional Property')}
                </Button>
              </>
            )}
          </FormList>
        </Form>
      </Drawer>
    </ConfigProvider>
  );
}
