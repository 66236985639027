import React from 'react';
import {
  COLORS,
  Flex,
  FONTS,
  HeaderActions,
  SPACING,
  Typography,
} from '@optii/ui-library';

type Props = {
  onClose: () => void;
  edit?: boolean;
};

function Title({ onClose, edit }: Props) {
  return (
    <Flex align="center" justify="space-between">
      <Typography.Title
        level={4}
        style={{
          marginTop: SPACING.NONE,
          fontFamily: 'Montserrat',
          fontWeight: 500,
          fontSize: FONTS.h3.size,
          color: COLORS.neutral[8],
        }}
      >
        {edit ? 'Edit Team Member' : 'Add Team Member'}
      </Typography.Title>
      <HeaderActions onClose={onClose} />
    </Flex>
  );
}

export default Title;
