import { SUPPORTED_REPORT_LANGUAGES } from '@optii/global';

export const SUPPORTED_LANGUAGES = SUPPORTED_REPORT_LANGUAGES;

export const SIGMA_EVENTS = {
  outbound: 'action:outbound',
};

export const SIGMA_EVENT_NAME = {
  createChecklist: 'Create-Checklist',
  createJob: 'Create-Job',
  updateChecklist: 'Edit-Checklist',
  inviteUser: 'Invite-User',
  editUser: 'Edit-User',
};

export const SIGMA_ORIGIN = 'https://app.sigmacomputing.com';
