import React, {
  useCallback,
  useContext,
  useRef,
  useMemo,
  ReactNode,
  useState,
} from 'react';
import { useFormik, FormikContext } from 'formik';
import styled from 'styled-components/macro';
import _, { update } from 'lodash';

import {
  FormikField,
  Session,
  EmploymentContext,
  GA_EVENTS,
  FormBlock,
  GoogleAnalyticsClient,
  escapeCommas,
  ButtonPrimary as SubmitButton,
  ButtonQuarternary as CancelButton,
} from '@optii/shared';
import { useTranslation } from 'react-i18next';

import { v4 as uuidv4 } from 'uuid';
import {
  COLORS,
  ConfigProvider,
  Flex,
  FONTS,
  Form,
  FormItem,
  SPACING,
  TreeSelect,
  Typography,
} from '@optii/ui-library';
import { useApolloClient } from '@apollo/client';
import {
  useChecklistTemplateNameCollisionLazyQuery,
  ChecklistTemplateNameCollisionDocument,
  useCopyChecklistTemplateMutation,
  useCreateChecklistTemplateMutation,
  useUpdateChecklistTemplateMutation,
  useDeleteChecklistTemplateMutation,
} from '../../../api/checklists';
import CheckListTemplate from './CheckListTemplate';
import { CHECKLIST_TYPES } from '../../taskConstants';
import CheckList from '../..';
import { CHECKLIST_TASK_TYPE_CONFIG } from '../../constants';
import { ChecklistScore } from './ChecklistScore';
import { ChecklistModalContext } from '../../contexts';

const CheckListFormFooter = styled(FormBlock.Footer as 'div')`
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #fff;
  padding: 1.6rem 1.5rem 2rem 1.5rem;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  button {
    margin-left: 2rem;
  }
`;

type PropertyData = {
  value: string;
  label: string;
};

type Props = {
  isEdit?: boolean;
  isAdd?: boolean;
  isCopy?: boolean;
  readOnly?: boolean;
  data: any;
  onFinish: (v: any) => void;
};

export default function ChecklistTemplateForm({
  isEdit,
  isAdd,
  isCopy,
  readOnly,
  data,
  onFinish,
}: Props) {
  const { globalSnack } = useContext(Session);
  const { t } = useTranslation(['common', 'checklist']);
  const { employee } = useContext(EmploymentContext.Context);
  const {
    properties,
    isAboveProperty,
    selectedProperties,
    mappedCreatorIds,
    existingAPChecklists,
  } = useContext(ChecklistModalContext);
  const [propertiesToRemove, setPropertiesToRemove] = useState<string[]>([]);
  const [propertiesToAdd, setPropertiesToAdd] = useState<string[]>([]);

  const isDuplicateRef = useRef(false);
  const existingChecklistsInPropertiesRef = useRef<PropertyData[]>([]);
  const client = useApolloClient();

  const isInAboveProperty = window.document.URL.includes(
    'above-property/checklists',
  );

  const findPropertyNameInProperties = useCallback(
    (
      items: typeof properties,
      propertyIds: string[],
      formattedProperties: PropertyData[] = [],
    ) => {
      items.map((item) => {
        if (propertyIds.includes(item.value)) {
          formattedProperties.push({
            label: item.title,
            value: item.value,
          });
        }
        return findPropertyNameInProperties(
          item.children as typeof properties,
          propertyIds,
          formattedProperties,
        );
      });

      return formattedProperties;
    },
    [],
  );

  const [checkForDuplicateName, { loading: checkForDuplicateNameLoading }] =
    useChecklistTemplateNameCollisionLazyQuery({
      context: { _instance: 'node' },
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
    });

  const [
    createChecklistTemplate,
    { loading: onCreateChecklistTemplateLoading },
  ] = useCreateChecklistTemplateMutation({
    context: { _instance: 'node' },
  });

  const [
    updateChecklistTemplate,
    { loading: onUpdateChecklistTemplateLoading },
  ] = useUpdateChecklistTemplateMutation({
    context: { _instance: 'node' },
  });

  const [deleteChecklistTemplate] = useDeleteChecklistTemplateMutation({
    context: { _instance: 'node' },
  });

  const [copyChecklistTemplate, { loading: onCopyChecklistTemplateLoading }] =
    useCopyChecklistTemplateMutation({
      context: { _instance: 'node' },
    });

  const validateForm = async (values: {
    displayName?: string;
    properties?: string[];
  }) => {
    const errors: { displayName: ReactNode; properties: ReactNode } = {
      displayName: '',
      properties: '',
    };

    if (
      isAboveProperty &&
      isInAboveProperty &&
      values.properties?.length === 0
    ) {
      errors.properties = t('checklist:Properties are required.');
    }

    if (!values?.displayName?.trim()) {
      errors.displayName = t('checklist:Checklist name is a required field.');
    } else if (existingAPChecklists.includes(values.displayName.trim())) {
      errors.displayName = t(
        'checklist:A checklist with this name already exists in one or more of your properties. Try a different name.',
      );
    } else if (isDuplicateRef.current) {
      if (
        isAboveProperty &&
        isInAboveProperty &&
        existingChecklistsInPropertiesRef.current
      ) {
        errors.displayName = (
          <div>
            {t(
              'checklist:A checklist with this name already exists in the following properties: ',
            )}
            <ul
              style={{
                listStyle: 'disc',
                marginTop: SPACING.SIZE_XXS,
                paddingLeft: SPACING.SIZE_XL,
              }}
            >
              {existingChecklistsInPropertiesRef.current.map((item) => (
                <li
                  style={{
                    marginBottom: SPACING.SIZE_XS,
                  }}
                >
                  {item.label}
                </li>
              ))}
            </ul>
          </div>
        );
      } else
        errors.displayName = t(
          'checklist:A checklist with this name already exists.',
        );
    }

    return errors;
  };

  const initValues = () => {
    if (data) {
      return {
        displayName: data.name,
        description: data.description || '',
        checkList: data.tasks,
        properties: selectedProperties,
        enableScoring: data.enableScoring || false,
      };
    }

    return {
      displayName: '',
      description: '',
      enableScoring: false,
    };
  };

  const formik = useFormik<{
    displayName: string;
    description?: string;
    checkList?: any;
    enableScoring?: boolean;
    properties?: string[];
  }>({
    validate: validateForm,
    initialValues: initValues(),
    onSubmit: async () => {
      console.info('Submitting...');
    },
  });

  const validateChecklistName = useCallback(
    async (values: any, currentName: string, isEditMode: boolean) => {
      if (isEditMode && currentName === escapeCommas(values.displayName))
        return { isValid: true };

      const name = escapeCommas(values.displayName) as string;
      if (isAboveProperty && isInAboveProperty) {
        const existingNameInPropertiesIds: string[] = [];

        const promises = values.properties.map(async (property: string) => {
          const { data: checklistTemplateNameCollisionData } =
            await client.query({
              query: ChecklistTemplateNameCollisionDocument,
              variables: {
                name: `${name}`,
                property,
              },
              context: {
                _instance: 'node',
                _shard: property,
              },
            });

          if (checklistTemplateNameCollisionData?.nameExists) {
            existingNameInPropertiesIds.push(property);
          }
        });

        await Promise.all(promises);

        if (existingNameInPropertiesIds.length > 0) {
          const formattedProperties = findPropertyNameInProperties(
            properties,
            existingNameInPropertiesIds,
          );

          isDuplicateRef.current = true;
          existingChecklistsInPropertiesRef.current = formattedProperties;
          formik.validateForm();
          return { isValid: false };
        }

        return { isValid: true };
      }
      const { data: checkForDupName } = await checkForDuplicateName({
        variables: {
          name,
        },
      });

      if (checkForDupName?.nameExists) {
        isDuplicateRef.current = true;
        formik.validateForm();
        return {
          isValid: false,
        };
      }
      return {
        isValid: true,
      };
    },
    [
      checkForDuplicateName,
      formik,
      isAboveProperty,
      client,
      findPropertyNameInProperties,
      properties,
      isInAboveProperty,
    ],
  );

  const formatTasksForSubmission = useCallback(
    (checkList: any[], checklistTemplateId?: string) => {
      let tasks = checkList?.filter((item) => !!item.label);
      let parentTaskId: string | null = null;

      tasks = tasks?.map((task, index) => {
        const {
          attachment,
          id,
          label,
          note,
          taskType,
          required = false,
          defaultValue,
          pointsComplete,
          pointsIncomplete,
        } = task;
        const isGroupHeader = taskType === CHECKLIST_TYPES.groupHeader;

        if (isGroupHeader) {
          parentTaskId = id;
        }

        let fulfillmentDefaultValues = {};
        if (
          typeof CHECKLIST_TASK_TYPE_CONFIG[taskType]?.fulfillment
            ?.getDefaultValue === 'function'
        ) {
          fulfillmentDefaultValues =
            CHECKLIST_TASK_TYPE_CONFIG[taskType]?.fulfillment?.getDefaultValue(
              task,
            );
        }

        const newItem = {
          attachment: attachment ?? [],
          id: isCopy ? uuidv4() : id,
          checklistTemplateId,
          taskType,
          notes: note || '',
          label,
          pointsComplete,
          pointsIncomplete,
          parent_task_id: isGroupHeader ? null : parentTaskId,
          ordering_value: String(index),
          required,
          defaultValue,
          ...fulfillmentDefaultValues,
        };

        return newItem;
      });

      return tasks;
    },
    [isCopy],
  );

  async function handleAddSubmit(values: any) {
    const checklistTemplateId = uuidv4();
    const employeeId = typeof employee === 'boolean' ? '' : employee.id;
    const createChecklistTemplateInput = {
      id: checklistTemplateId,
      name: values.displayName,
      description: values.description,
      fromAPConsole: isAboveProperty,
      creator: {
        employeeId: parseInt(employeeId, 10),
      },
      enableScoring: values.enableScoring,
      tasks: formatTasksForSubmission(values.checkList, checklistTemplateId),
    };

    try {
      if (isAboveProperty) {
        Promise.all(
          values.properties.map(async (item: string) => {
            await createChecklistTemplate({
              variables: {
                input: createChecklistTemplateInput,
              },
              context: { _instance: 'node', _shard: item },
            });
          }),
        )
          .then(() => {
            globalSnack({
              message: t(
                'checklist:{{name}} was successfully added to the selected properties',
                {
                  name: values.displayName,
                },
              ),
              timeout: 6000,
            });
            if (typeof onFinish === 'function') onFinish({ refresh: true });
          })
          .catch((error) => {
            console.error(error);
            globalSnack({
              message: t(
                'checklist:Something went wrong attempting to create a checklist',
              ),
              error: true,
              timeout: 6000,
            });
          });
      } else {
        await createChecklistTemplate({
          variables: {
            input: createChecklistTemplateInput,
          },
          context: { _instance: 'node' },
        });

        globalSnack({
          message: t('checklists:{{name}} was successfully added', {
            name: values.displayName,
          }),
        });
        if (typeof onFinish === 'function') onFinish({ refresh: true });
      }
    } catch (e) {
      console.error('Error creating checklist template', e);

      globalSnack({
        message: t(
          'checklists:Something went wrong attempting to create checklist',
        ),
        error: true,
        timeout: 5000,
      });
    }
  }

  async function handleUpdateSubmit(values: any) {
    const employeeId = typeof employee === 'boolean' ? '' : employee.id;

    const updateChecklistTemplateInput = {
      id: data.id,
      createdAt: data.createdAt,
      name: values.displayName,
      description: values.description,
      fromAPConsole: isAboveProperty,
      enableScoring: values.enableScoring,
      creator: {
        id: parseInt(data.creator?.id, 10),
        employeeId: parseInt(data.employee?.id ?? employeeId, 10),
        createdAt: data.creator?.createdAt,
        firstName: data.creator?.firstName,
        lastName: data.creator?.lastName,
      },
      tasks: formatTasksForSubmission(values.checkList, data.id),
    };

    try {
      if (isAboveProperty) {
        if (propertiesToAdd.length) {
          Promise.all(
            propertiesToAdd.map(async (item) => {
              const input = {
                ...updateChecklistTemplateInput,
                creator: {
                  employeeId: parseInt(data.employee?.id ?? employeeId, 10),
                },
              };
              delete input.createdAt;
              await createChecklistTemplate({
                variables: {
                  input: {
                    ...input,
                  },
                },
                context: {
                  _instance: 'node',
                  _shard: item,
                },
              });
            }),
          );
        }

        if (propertiesToRemove.length) {
          Promise.all(
            propertiesToRemove.map(async (item) => {
              await deleteChecklistTemplate({
                variables: {
                  id: data.id,
                  fromAPConsole: true,
                },
                context: {
                  _instance: 'node',
                  _shard: item,
                },
              });
            }),
          );
        }

        Promise.all(
          values.properties
            .filter((item: string) => !propertiesToAdd.includes(item))
            .map(async (item: string) => {
              const propertyBasedInput = {
                ...updateChecklistTemplateInput,
                creator: {
                  ...updateChecklistTemplateInput.creator,
                  id: parseInt(mappedCreatorIds[item], 10),
                },
              };

              await updateChecklistTemplate({
                variables: {
                  id: data.id,
                  input: propertyBasedInput,
                },
                context: {
                  _instance: 'node',
                  _shard: item,
                },
              });
            }),
        ).then(() => {
          globalSnack({
            message: t('checklist:{{name}} was successfully updated', {
              name: values.displayName,
            }),
          });
          if (typeof onFinish === 'function') onFinish({ refresh: true });
        });
      } else {
        await updateChecklistTemplate({
          variables: {
            id: data.id,
            input: updateChecklistTemplateInput,
          },
          context: { _instance: 'node' },
        });

        globalSnack({
          message: t('checklists:{{name}} was successfully updated', {
            name: values.displayName,
          }),
        });

        if (typeof onFinish === 'function') onFinish({ refresh: true });
      }
    } catch (e) {
      console.error('Error updating checklist template', e);

      globalSnack({
        message: t(
          'checklists:Something went wrong attempting to update checklist',
        ),
        error: true,
        timeout: 5000,
      });
    }
  }

  async function handleCopySubmit(values: any) {
    const employeeId = typeof employee === 'boolean' ? '' : employee.id;
    const checklistTemplateId = uuidv4();
    const copyChecklistTemplateInput = {
      id: checklistTemplateId,
      name: values.displayName,
      description: values.description,
      creator: {
        employeeId: parseInt(employeeId, 10),
      },
      tasks: formatTasksForSubmission(values.checkList, checklistTemplateId),
    };

    try {
      await copyChecklistTemplate({
        variables: {
          id: data.id,
          copyChecklistTemplateInput,
        },
        context: { _instance: 'node' },
      });

      globalSnack({
        message: t('checklists:{{name}} was successfully copied', {
          name: copyChecklistTemplateInput.name,
        }),
      });

      if (typeof onFinish === 'function') onFinish({ refresh: true });
    } catch (e) {
      console.error('Error copying checklist template', e);

      globalSnack({
        message: t(
          'checklists:Something went wrong attempting to copy checklist',
        ),
        error: true,
        timeout: 5000,
      });
    }
  }

  async function onFormSubmit(values: any) {
    const { description } = values;
    const { isValid } = await validateChecklistName(
      values,
      data?.name,
      isEdit || isCopy || false,
    );

    if (description !== '')
      GoogleAnalyticsClient.event(GA_EVENTS.checklistWithDescription);
    if (!isValid) return;

    if (isCopy) handleCopySubmit(values);
    else if (isEdit) handleUpdateSubmit(values);
    else handleAddSubmit(values);
  }

  const actionText = (isEditChecklist?: boolean) => {
    if (isCopy) {
      if (onCopyChecklistTemplateLoading) {
        return t('common:Copying...');
      }
      return t('common:Copy');
    }
    if (isEditChecklist) {
      if (onUpdateChecklistTemplateLoading) {
        return t('common:Updating...');
      }
      return t('common:Update');
    }
    if (onCreateChecklistTemplateLoading) {
      return t('common:Saving...');
    }
    return t('common:Save');
  };

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    setFieldTouched,
    setFieldValue,
    setFieldError,
    setErrors,
  } = formik;

  const handleChecklistChange = ({
    data: checklistData,
    isValid,
  }: {
    data: any;
    isValid: boolean;
  }) => {
    setFieldValue('checkList', checklistData, false);

    if (!isValid) {
      setFieldError('checkList', 'checklist error');
    } else {
      const { checkList, ...newErros } = errors;
      setErrors(newErros);
    }
  };

  const canSave = useMemo(
    () =>
      Object.keys(errors)?.filter((key) => errors[key as keyof typeof errors])
        .length === 0 &&
      values.displayName?.trim() !== null &&
      formatTasksForSubmission(values.checkList)?.length > 0,
    [errors, values.displayName, values.checkList, formatTasksForSubmission],
  );

  const canUpdate = useMemo(() => {
    if (!canSave) return false;
    const initialValues = initValues();
    initialValues.checkList = formatTasksForSubmission(initialValues.checkList);
    const currentValues = {
      displayName: values.displayName?.trim(),
      description: values.description,
      checkList: formatTasksForSubmission(values.checkList),
    };
    const hasUpdates = !_.isEqual(initialValues, currentValues);
    return hasUpdates;
  }, [canSave, values]);

  const checkAndSubmit = async () => {
    if (isCopy) {
      GoogleAnalyticsClient.event(GA_EVENTS.checklistCopy);
    } else if (isEdit) {
      GoogleAnalyticsClient.event(GA_EVENTS.checklistUpdate);
    } else if (canSave) {
      GoogleAnalyticsClient.event(GA_EVENTS.checklistSave);
    }
    await onFormSubmit(values);
  };

  const loading =
    onCreateChecklistTemplateLoading ||
    onUpdateChecklistTemplateLoading ||
    checkForDuplicateNameLoading;

  const providerValue = useMemo(() => ({ ...formik }), [formik]);

  return (
    <FormikContext.Provider value={providerValue}>
      <CheckList.TopModalContent>
        {isEdit && (
          <CheckList.TemplateMessage data-testid="checklist-template-edit-message">
            {t('checklist:Checklist updates only apply to:')}
            <ul>
              <li>{t('checklist:Unassigned project jobs')}</li>
              <li>{t('checklist:Repeating jobs')}</li>
            </ul>
          </CheckList.TemplateMessage>
        )}
        {isAboveProperty ? (
          <Form
            onValuesChange={(c, formValues) => {
              formik.setValues((prev) => ({
                ...prev,
                properties: formValues.properties,
              }));
              formik.validateForm();
            }}
            initialValues={{
              properties: formik.values.properties,
            }}
            style={{
              marginBottom: 40,
            }}
          >
            <FormItem
              name="properties"
              label={t('common:Properties')}
              required
              rules={[
                {
                  required: true,
                  message: t('checklist:Properties are required'),
                },
              ]}
            >
              <TreeSelect
                treeData={properties}
                v2
                onSelect={(value) => {
                  setPropertiesToAdd((prev) =>
                    prev
                      .concat(value)
                      .filter(
                        (property) => !selectedProperties.includes(property),
                      ),
                  );
                  setPropertiesToRemove((prev) =>
                    prev.filter((item) => item !== value),
                  );
                }}
                onDeselect={(value) => {
                  setPropertiesToAdd((prev) =>
                    prev.filter((item) => item !== value),
                  );
                  setPropertiesToRemove((prev) =>
                    prev
                      .concat(value)
                      .filter((item) => selectedProperties.includes(item)),
                  );
                }}
                treeCheckable
                multiple
                showSearch
                treeNodeFilterProp="title"
                showCheckedStrategy="SHOW_CHILD"
                treeDefaultExpandedKeys={['1']}
                virtual={false}
                key="value"
              />
            </FormItem>
          </Form>
        ) : null}
        <FormikField
          styles={{ marginBottom: '1.5rem' }}
          name="displayName"
          label={t('checklist:Checklist Name')}
          type="formikText"
          placeholder={t('checklist:Enter Checklist Name')}
          required
          errors={errors}
          value={values.displayName}
          onChange={(e: any) => {
            if (isDuplicateRef.current) {
              isDuplicateRef.current = false;
              existingChecklistsInPropertiesRef.current = [];
              formik.validateForm();
            }

            setFieldValue('displayName', e.target.value, false);
          }}
          onBlur={handleBlur}
          setFieldTouched={setFieldTouched}
          touched={touched}
        />
        <FormikField
          name="description"
          label={t('checklist:Description')}
          type="text"
          placeholder=""
          required={false}
          errors={errors}
          value={values.description}
          onChange={handleChange}
          onBlur={handleBlur}
          setFieldTouched={setFieldTouched}
          touched={touched}
        />

        <ChecklistScore setFieldValue={setFieldValue} values={values} />
      </CheckList.TopModalContent>
      <CheckListTemplate
        isEdit={isEdit || isCopy}
        isAdd={isAdd}
        isReadOnly={readOnly}
        onChange={handleChecklistChange}
        data={values.checkList}
        isScoringEnabled={values.enableScoring}
        footer={
          <CheckListFormFooter>
            <CancelButton onClick={onFinish} type="button">
              {t('common:Cancel')}
            </CancelButton>
            <SubmitButton
              data-testid="Save"
              type="submit"
              disabled={!canUpdate || !canSave || loading}
              onClick={checkAndSubmit}
            >
              {actionText(isEdit)}
            </SubmitButton>
          </CheckListFormFooter>
        }
      />
    </FormikContext.Provider>
  );
}
