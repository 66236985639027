import React, {
  CSSProperties,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  COLORS,
  ConfigProvider,
  Avatar,
  Divider,
  Flex,
  Layout,
  Menu,
  MenuProps,
  SPACING,
  ThemeConfig,
  Typography,
  FONTS,
  Skeleton,
} from '@optii/ui-library';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { OptiiLogo } from '@optii/global';
import { ExportOutlined } from '@ant-design/icons';
import {
  getEnvironment,
  PERMISSIONS,
  useAccess,
  UserAccessContext,
} from '@optii/shared';

const { Header: LayoutHeader } = Layout;

const TYPOGRAPHY_TITLE: CSSProperties = {
  fontWeight: 500,
  fontSize: FONTS.h4.size,
  letterSpacing: FONTS.h4.letterSpacing,
  fontFamily: 'Montserrat',
};

const TYPOGRAPHY_TEXT: CSSProperties = {
  fontWeight: 500,
  color: COLORS.neutral[8],
  letterSpacing: FONTS.medium.letterSpacing,
};

const THEME: ThemeConfig = {
  components: {
    Menu: {
      controlHeightLG: 20,
      itemPaddingInline: SPACING.SIZE_MD,
      horizontalLineHeight: '1 !important',
      itemColor: COLORS.neutral[8],
    },
    Button: {
      controlHeight: 36,
      paddingInline: SPACING.SIZE_SM,
      paddingBlock: 6,
      defaultBg: 'rgba(255, 101, 65, 0.4)',
      defaultBorderColor: COLORS.volcano[6],
      defaultActiveBorderColor: COLORS.volcano[6],
      defaultHoverBorderColor: COLORS.volcano[6],
      colorText: 'rgba(139, 61, 43, 1)',
      defaultHoverColor: 'rgba(139, 61, 43, 1)',
      defaultActiveColor: 'rgba(139, 61, 43, 1)',
      defaultActiveBg: 'rgba(255, 101, 65, 0.8)',
      defaultHoverBg: 'rgba(255, 101, 65, 0.8)',
    },
    Divider: {
      verticalMarginInline: SPACING.SIZE_MD,
      colorSplit: COLORS.neutral[5],
    },
  },
};

const STYLE: CSSProperties = {
  backgroundColor: COLORS.neutral[1],
  paddingInline: SPACING.SIZE_DEFAULT,
  paddingTop: SPACING.SIZE_MD,
  display: 'flex',
  flexDirection: 'column',
};

const LINK_SUFFIX = '/above-property';

type RouteItems = {
  [key: string]: {
    label: string;
    link: string;
    disabled: boolean;
  };
};

const FLAG = ['DEV', 'LOCAL', 'TEST'].includes(getEnvironment());

export function Header() {
  const history = useHistory();
  const { t } = useTranslation(['common']);
  const { user } = useContext(UserAccessContext.Context);
  const { can } = useAccess();
  const [selectedKeys, setSelectedKeys] = useState<Array<string> | undefined>(
    undefined,
  );

  const canAccessJobManagement = can(
    PERMISSIONS.aboveProperty.above_prop_ctrl_view_job_management,
  );

  const canAccessChecklistManagement = can(
    PERMISSIONS.aboveProperty.above_prop_ctrl_view_checklist,
  );

  const canAccessAppConfiguration = can(
    PERMISSIONS.aboveProperty.above_prop_ctrl_view_app_configuration,
  );

  useEffect(() => {
    if (!selectedKeys) {
      const currentPath = history.location.pathname.replace(LINK_SUFFIX, '');

      setSelectedKeys([currentPath]);
    }
  }, [selectedKeys, history.location.pathname]);

  const routeItems: RouteItems = {
    '/app-configuration': {
      link: `${LINK_SUFFIX}/app-configuration`,
      label: t('common:App Configuration'),
      disabled: !canAccessAppConfiguration,
    },
    '/jobs-management': {
      link: `${LINK_SUFFIX}/jobs-management`,
      label: t('common:Jobs Management'),
      disabled: !canAccessJobManagement,
    },
    '/user-management': {
      link: `${LINK_SUFFIX}/user-management`,
      label: t('common:User Management'),
      disabled: !FLAG,
    },
    '/checklists-management': {
      link: `${LINK_SUFFIX}/checklists-management`,
      label: t('common:Checklists'),
      disabled: !canAccessChecklistManagement,
    },
  };

  const fullName = `${user?.firstName} ${user?.lastName}`;
  const onClickReturnToOptii = useCallback(() => {
    sessionStorage.removeItem('orgId');
    history.replace('/');
    history.go(0);
  }, [history]);

  const items: MenuProps['items'] = Object.keys(routeItems).map((key) => ({
    key,
    label: (
      <Link to={routeItems[key as keyof typeof routeItems].link}>
        {routeItems[key].label}
      </Link>
    ),
    style: {
      height: SPACING.SIZE_XL,
      display: routeItems[key].disabled ? 'none' : 'unset',
    },
  }));

  return (
    <ConfigProvider theme={THEME}>
      <LayoutHeader style={STYLE}>
        <Flex
          align="center"
          justify="space-between"
          style={{
            height: 46,
            marginBottom: SPACING.SIZE_DEFAULT,
            paddingInline: SPACING.SIZE_MD,
          }}
        >
          <Flex align="center">
            <OptiiLogo />
            <Divider
              type="vertical"
              style={{
                height: 30,
              }}
            />
            <Typography.Title level={4} style={TYPOGRAPHY_TITLE}>
              {t('common:Above Property')}
            </Typography.Title>
          </Flex>

          <Flex align="center">
            <Button
              type="default"
              onClick={onClickReturnToOptii}
              style={{
                marginRight: SPACING.SIZE_MD,
              }}
              icon={<ExportOutlined />}
              iconPosition="end"
            >
              {t('common:Back to Optii')}
            </Button>
            <Skeleton
              active
              loading={!user}
              avatar={{
                style: {
                  paddingRight: SPACING.SIZE_DEFAULT,
                },
              }}
              title={false}
              round
              paragraph={{
                rows: 1,
                width: 100,
              }}
              style={{
                lineHeight: SPACING.NONE,
              }}
            >
              <Avatar
                style={{
                  verticalAlign: 'center',
                  marginRight: SPACING.SIZE_DEFAULT,
                }}
              >
                {fullName.charAt(0)}
              </Avatar>

              <Typography.Text style={TYPOGRAPHY_TEXT}>
                {fullName}
              </Typography.Text>
            </Skeleton>
          </Flex>
        </Flex>
        <Menu
          items={items}
          mode="horizontal"
          defaultSelectedKeys={['app-configuration']}
          selectedKeys={selectedKeys}
          onClick={({ key }) => {
            setSelectedKeys([key]);
          }}
          style={{
            flex: 'auto',
            minWidth: SPACING.NONE,
            height: SPACING.SIZE_XXL,
            paddingRight: SPACING.SIZE_MD,
            alignItems: 'end',
          }}
        />
      </LayoutHeader>
    </ConfigProvider>
  );
}
