import React, { Dispatch, SetStateAction } from 'react';
import { Button, Flex, FormInstance, SPACING } from '@optii/ui-library';

type Props = {
  onCancel: () => void;
  setSendNow: Dispatch<SetStateAction<boolean>>;
  form: FormInstance;
  loading: boolean;
  edit: boolean;
};

export function Footer({ setSendNow, onCancel, form, loading, edit }: Props) {
  return (
    <Flex gap={SPACING.SIZE_MD} align="center" justify="flex-end">
      <Button type="text" onClick={onCancel}>
        Cancel
      </Button>

      {edit ? (
        <Button
          type="default"
          htmlType="submit"
          loading={loading}
          disabled={loading}
          onClick={() => {
            form.submit();
          }}
        >
          Update Invitations
        </Button>
      ) : (
        <>
          <Button
            type="default"
            htmlType="submit"
            loading={loading}
            disabled={loading}
            onClick={() => {
              setSendNow(false);
              form.submit();
            }}
          >
            Save and send later
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={loading}
            onClick={() => {
              setSendNow(true);
              form.submit();
            }}
          >
            Send invitation
          </Button>
        </>
      )}
    </Flex>
  );
}
