import { Fragment, SyntheticEvent, useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { JobNotesDropdown } from '@optii-solutions/ui23-antd-components';
import {
  Button,
  COLORS,
  FONTS,
  Flex,
  SPACING,
  TextArea,
} from '@optii-solutions/ui-library';

import { EmploymentContext, usePropertyTime } from '@optii/shared';
import {
  formatUserDisplayName,
  TFormatUserDisplayName,
} from '@optii/jobs/formatters/user';
import { useJobNoteEditor } from '@optii/topcat-client/data/jobs';

import { StringParam, useQueryParam } from 'use-query-params';
import { JobNotesEdit } from './JobNotesEdit';
import { useJobNotes } from './JobNotes.hooks';
import {
  THandleAddNote,
  TJobNotesProps,
  TJobNotesPropsJobNotes,
  TNote,
} from './JobNotes.types';

const Author = styled.h4`
  ${(props) => props.theme.fonts.sizes.small}
  ${(props) => props.theme.fonts.type.primary}
  ${(props) => props.theme.fonts.weights.weak}
  flex: 1;
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const Time = styled.div`
  ${(props) => props.theme.fonts.sizes.small}
  margin-left: auto;
  color: ${(props) => props.theme.colors.charcoal05};
  font-style: italic;
  text-align: right;
`;

const Text = styled.p`
  ${(props) => props.theme.fonts.sizes.small}
  padding-top: 1rem;
  margin: 0 !important;
  font-weight: 400;
  color: ${(props) => props.theme.colors.charcoal05};
`;

const Entry = styled.div`
  border-bottom: solid 1px ${(props) => props.theme.colors.charcoal02};
  padding-bottom: 1.5rem;
  padding-top: 1rem;
`;

const Entries = styled.div``;

const Container = styled.div``;

const TextField = styled.input`
  ${(props) => props.theme.fonts.sizes.small};
  ${(props) => props.theme.fonts.weights.medium};
  text-align: end;
  padding: 0;
  border: none;
  cursor: text;
  font-family: 'Roboto';
  color: ${COLORS.neutral[9]};
  background-color: transparent;
`;

export function Note(props: TNote) {
  const {
    entry: note,
    toPropertyTime,
    canEdit,
    refetch,
    job,
    setActiveTab,
  } = props;

  const [shard] = useQueryParam('shard', StringParam);
  const { t } = useTranslation(['common', 'fields'], { useSuspense: false });
  const { editLoading, removeLoading, update, remove, edit, setEdit } =
    useJobNoteEditor({
      job,
      shard,
    });
  if (!note?.text) {
    return null;
  }
  const legacyTime = note?.createdAt ? moment(note?.createdAt).unix() : false;
  const time = note?.updatedAt ?? note?.time;
  const localizedTime = legacyTime
    ? toPropertyTime(legacyTime)
    : toPropertyTime(time || '');

  return (
    <Entry
      style={{
        opacity: removeLoading ? '0.3' : '1',
        backgroundColor: COLORS.neutral[1],
        display: 'flex',
        minWidth: '100%',
        flexDirection: 'column',
      }}
    >
      {edit ? (
        <JobNotesEdit
          note={note?.text}
          loading={editLoading}
          name={formatUserDisplayName(
            note?.createdBy as TFormatUserDisplayName,
          )}
          onCancel={() => setEdit(false)}
          onSave={async (input) => {
            await update({ note: input, id: note?.id });

            if (typeof refetch === 'function') {
              setActiveTab('2');

              await refetch();
            }
          }}
        />
      ) : (
        <>
          <Top
            style={{
              minWidth: '100%',
            }}
          >
            <Author>
              <TextField
                value={
                  note?.createdBy
                    ? formatUserDisplayName(
                        note.createdBy as TFormatUserDisplayName,
                      )
                    : t('common:Unknown')
                }
                type="button"
              />
            </Author>
            <Time>{localizedTime?.format('ll[,] LTS')}</Time>
            {canEdit && (
              <JobNotesDropdown
                onEdit={() => !removeLoading && setEdit(true)}
                onRemove={async () => {
                  if (!removeLoading) {
                    await remove({
                      id: note?.id,
                    });
                  }

                  if (typeof refetch === 'function') {
                    setActiveTab('2');

                    await refetch();
                  }
                }}
              />
            )}
          </Top>
          <Text>
            {note?.text.split('\n').map((item: string, index: number) => {
              const key = `${item}-${index}`;

              return (
                <Fragment key={key}>
                  {item}
                  <br />
                </Fragment>
              );
            })}
          </Text>
        </>
      )}
    </Entry>
  );
}

export function JobNotes(props: TJobNotesProps) {
  const {
    job,
    refetch,
    updateJobLoading,
    isQueuedJob = false,
    setActiveTab,
  } = props;

  const [shard] = useQueryParam('shard', StringParam);

  const { handleAddNote } = useJobNotes({
    job,
    jobRefetch: refetch,
    setActiveTab,
    shard,
  });

  const [note, setNote] = useState<string>('');

  const { t } = useTranslation(['assets', 'common', 'jobs'], {
    useSuspense: false,
  });

  const { employee } = useContext(EmploymentContext.Context);
  const { toPropertyTime } = usePropertyTime(null);
  const notes = job?.notes;

  return (
    <Container {...props} style={{ minWidth: '100%' }}>
      <Entries data-testid="job-notes-entries">
        {notes?.length
          ? notes?.map((notesItem) => (
              <Note
                key={`note-entry-${notesItem?.id}`}
                canEdit={employee?.id === notesItem?.createdBy?.id}
                entry={notesItem as TJobNotesPropsJobNotes}
                toPropertyTime={toPropertyTime}
                refetch={refetch}
                job={job}
                setActiveTab={setActiveTab}
              />
            ))
          : null}
      </Entries>

      <Flex
        vertical
        gap={SPACING.SIZE_XS}
        style={{
          backgroundColor: COLORS.neutral[1],
          paddingTop: SPACING.SIZE_DEFAULT,
        }}
      >
        {!isQueuedJob ? (
          <>
            <label htmlFor="notes">
              <span
                style={{
                  minWidth: '100%',
                  fontWeight: 500,
                  fontSize: `${FONTS.xSmall.size}px`,
                }}
              >
                {t('fields:Notes')}
              </span>
            </label>

            <Flex
              gap={SPACING.SIZE_MD}
              align="center"
              style={{
                marginBottom: SPACING.SIZE_MD,
              }}
              data-testid="job-add-note"
            >
              <TextArea
                id="notes"
                rows={1}
                size="large"
                value={note}
                onChange={(event: SyntheticEvent<HTMLTextAreaElement>) => {
                  setNote(event.currentTarget.value);
                }}
              />
              <Button
                type="default"
                disabled={!note.trim() || updateJobLoading}
                onClick={async () => {
                  await handleAddNote({
                    id: job?.id,
                    setNote,
                    note,
                  } as THandleAddNote);
                }}
              >
                {t('jobs:Add Note')}
              </Button>
            </Flex>
          </>
        ) : null}
      </Flex>
    </Container>
  );
}
