import { useTranslation } from 'react-i18next';
import {
  Button,
  COLORS,
  Flex,
  FONTS,
  SPACING,
  Typography,
} from '@optii/ui-library';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { HEADER_FONT_STYLE } from '../utils';

type Props = {
  onEdit: () => void;
  onClose: () => void;
  userName?: string;
};

export const Title = ({ userName, onEdit, onClose }: Props) => {
  const { t } = useTranslation(['common', 'fields']);
  return (
    <Flex align="center" justify="space-between">
      <Typography.Title
        level={3}
        style={{
          ...HEADER_FONT_STYLE,
          fontSize: FONTS.h3.size,
          letterSpacing: FONTS.h3.letterSpacing,
        }}
      >
        {userName}
      </Typography.Title>

      <Flex gap={SPACING.SIZE_MD} align="center">
        <Button onClick={onEdit} icon={<EditOutlined />}>
          {t('Edit')}
        </Button>

        <Button onClick={onClose} icon={<CloseOutlined />} />
      </Flex>
    </Flex>
  );
};
