import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  COLORS,
  ConfigProvider,
  DescriptionsProps,
  DescriptionText,
  Drawer,
  FONTS,
  Skeleton,
  SPACING,
  Typography,
} from '@optii/ui-library';
import { useTranslation } from 'react-i18next';
import {
  DRAWER_WIDTH,
  SelectOption,
  useDepartmentsLazyQuery,
  useListEmploymentTypesLazyQuery,
  useListInvitesLazyQuery,
  useRoleLazyQuery,
} from '@optii/global';
import { Descriptions } from '@optii-solutions/ui-library';
import { UserAccessContext } from '@optii/shared';
import { Title } from './Title';
import { DataSource, UserData } from '../types';
import { Table } from './Table';
import { InviteUserForm } from '../form';

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  userData: UserData | undefined;
};

export function UserDetails({ open, setOpen, userData }: Props) {
  const [openEdit, setOpenEdit] = useState(false);
  const [dataSource, setDataSource] = useState<DataSource[]>([]);
  const [loadingDataSource, setLoadingDataSource] = useState(false);
  const { t } = useTranslation(['common']);
  const onClose = () => {
    setOpen(false);
  };

  const { access } = useContext(UserAccessContext.Context);
  const [getDepartments] = useDepartmentsLazyQuery();
  const [getRole] = useRoleLazyQuery();
  const [getEmploymentTypes] = useListEmploymentTypesLazyQuery();
  const [listInvites] = useListInvitesLazyQuery({
    variables: {
      cursor: '',
      orderBy: 'userFirstName ASC',
    },
  });

  const generateDataSource = useCallback(async () => {
    setLoadingDataSource(true);
    const employmentInfo = userData?.employmentInfo;
    if (employmentInfo) {
      await Promise.all(
        employmentInfo.map(async (info) => {
          const { data: employmentTypes } = await getEmploymentTypes({
            variables: {
              filters: {
                id: info.employmentType,
              },
            },
            context: {
              _shard: info.property,
            },
          });
          const employmentType = employmentTypes?.page?.edges?.[0].node;

          const { data: invites } = await listInvites({
            context: {
              _shard: info.property,
            },
          });

          const inviteCode = invites?.page.edges?.find(
            ({ node }) => node.userEmail === userData.userInfo.userEmail,
          )?.node.code;

          const { data: departments } = await getDepartments({
            context: {
              _shard: info.property,
            },
          });

          let formattedPositions;

          if (info.positions)
            await Promise.all(
              info.positions.map(async (position) => {
                const { data: role } = await getRole({
                  variables: {
                    id: String(position.role),
                  },
                  context: {
                    _shard: info.property,
                  },
                });

                const department = departments?.page.edges?.find(
                  (dep) => dep.node.value === position.department,
                )?.node;

                return {
                  department: {
                    label: department?.label,
                    value: department?.value,
                  },
                  role: {
                    label: role?.GetRoleById.name,
                    value: role?.GetRoleById.id,
                  },
                };
              }),
            ).then((res) => {
              formattedPositions = res;
            });

          const property = access?.Access.Properties.find(
            (item) => item.id === info.property,
          );
          return {
            id: property?.id || '',
            inviteCode,
            property: {
              label: property?.Name,
              value: property?.id,
            } as SelectOption,
            employmentType: {
              label: employmentType?.displayName,
              value: employmentType?.id,
            } as SelectOption,
            positions: formattedPositions,
          };
        }),
      ).then((res) => setDataSource(res));
    }
    setLoadingDataSource(false);
  }, [
    userData?.employmentInfo,
    getDepartments,
    getEmploymentTypes,
    getRole,
    access,
    listInvites,
    userData?.userInfo.userEmail,
  ]);

  useEffect(() => {
    generateDataSource();
  }, [generateDataSource]);

  const descriptionItems: DescriptionsProps['items'] = [
    {
      label: (
        <DescriptionText type="label">
          {t('common:First Name')}{' '}
        </DescriptionText>
      ),
      children: (
        <DescriptionText type="text" strong>
          {userData?.userInfo.userFirstName}
        </DescriptionText>
      ),
      span: 1,
    },
    {
      label: (
        <DescriptionText type="label">{t('common:Last Name')} </DescriptionText>
      ),
      children: (
        <DescriptionText type="text" strong>
          {userData?.userInfo.userLastName}
        </DescriptionText>
      ),
      span: 'filled',
    },
    {
      label: (
        <DescriptionText type="label">
          {t('common:Email Address')}{' '}
        </DescriptionText>
      ),
      children: (
        <DescriptionText type="text" strong>
          {userData?.userInfo.userEmail}
        </DescriptionText>
      ),
    },
    {
      label: (
        <DescriptionText type="label">
          {t('common:Mobile Number')}{' '}
        </DescriptionText>
      ),
      children: (
        <DescriptionText type="text" strong>
          {userData?.userInfo.userMobileNumber}
        </DescriptionText>
      ),
    },
  ];
  return (
    <ConfigProvider
      theme={{
        token: {
          colorText: COLORS.neutral[8],
        },

        components: {
          Descriptions: {
            colorTextLabel: COLORS.neutral[8],
            colorTextBase: 'red',
          },
          Table: {
            lineWidth: 1,
          },
          Typography: {
            colorTextHeading: COLORS.neutral[8],
          },
        },
      }}
    >
      <Drawer
        open={open}
        title={
          <Title
            userName={`${userData?.userInfo.userFirstName} ${userData?.userInfo.userLastName}`}
            onClose={onClose}
            onEdit={() => setOpenEdit(true)}
          />
        }
        onClose={onClose}
        destroyOnClose
        id="user-details"
        width={DRAWER_WIDTH.LARGE}
      >
        <Skeleton loading={false}>
          <Descriptions
            key={userData?.userInfo.userEmail}
            title={
              <Typography.Text
                style={{
                  letterSpacing: FONTS.medium.letterSpacing,
                }}
              >
                {t('common:Name and Contact Details')}
              </Typography.Text>
            }
            labelStyle={{
              color: COLORS.neutral[8],
              fontSize: FONTS.xSmall.size,
              fontWeight: 500,
              letterSpacing: FONTS.xSmall.letterSpacing,
              marginBottom: SPACING.NONE,
            }}
            contentStyle={{
              marginBottom: SPACING.SIZE_MD,
            }}
            colon={false}
            layout="vertical"
            column={2}
            items={descriptionItems}
          />
        </Skeleton>
        <Typography.Paragraph
          style={{
            letterSpacing: FONTS.medium.letterSpacing,
            fontWeight: 500,
            marginTop: SPACING.SIZE_SM,
            marginBottom: SPACING.SIZE_XXL,
          }}
        >
          {t('common:Employment Details')}
        </Typography.Paragraph>
        <Table dataSource={dataSource} loading={loadingDataSource} />

        <InviteUserForm
          open={openEdit}
          setOpen={setOpenEdit}
          properties={userData?.editProperties || []}
          edit
          userInfo={userData?.userInfo}
          employmentInfo={dataSource}
        />
      </Drawer>
    </ConfigProvider>
  );
}
