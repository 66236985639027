import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { useQueryParams, StringParam, BooleanParam } from 'use-query-params';
import { GoogleAnalyticsClient, GA_EVENTS, PrimaryButton } from '@optii/shared';
import { ChecklistModalContext } from './contexts';
import Details from './details/index';
import List from './list';
import SettingsMain from './blocks/settingsMain';

type TreeData = {
  title: string;
  value: string;
  children: {
    title: string;
    value: string;
  }[];
};

type AssociatedProperty = {
  title: string;
  value: string;
};

const AddChecklist = styled(PrimaryButton)`
  margin-left: 1.3rem;
`;

export default function Checklists({
  modalMode,
  setOpenModal,
  add,
  isAboveProperty,
  properties,
  selectedProperties = [],
  mappedCreatorIds,
  existingAPChecklists,
}: {
  modalMode: boolean;
  setOpenModal?: Dispatch<SetStateAction<boolean>>;
  add?: boolean;
  isAboveProperty?: boolean;
  properties: TreeData[];
  selectedProperties: string[];
  existingAPChecklists: string[];
  mappedCreatorIds: { [key: string]: string };
}) {
  const { t } = useTranslation(['checklists']);
  const [isAdd, setIsAdd] = useState(add || false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useQueryParams({
    edit: BooleanParam,
    copy: BooleanParam,
    openJob: StringParam,
    openChecklistTemplate: StringParam,
    fulfillment: StringParam,
    readOnlyChecklist: StringParam,
    refresh: StringParam,
  });

  const closeChecklist = useCallback(
    ({ refresh }: { refresh?: string }) => {
      if (typeof setOpenModal === 'function') setOpenModal(false);
      setQuery({
        openChecklistTemplate: undefined,
        edit: undefined,
        copy: undefined,
        fulfillment: undefined,
        readOnlyChecklist: undefined,
        refresh,
      });
      setIsAdd(false);
      setData(null);
    },
    [setOpenModal, setQuery],
  );

  const [isOpen, setOpen] = useState(false);
  const [isChildOpen, setChildOpen] = useState(false);
  const [associatedProperties, setAssociatedProperties] = useState<
    AssociatedProperty[]
  >([]);
  const isEdit = !!query.edit;
  const isCopy = !!query.copy;
  const isFulfillment = query.fulfillment && query.fulfillment !== '';
  const isReadOnly = query.readOnlyChecklist && query.readOnlyChecklist !== '';
  const readOnly = !isEdit && !isAdd && !isFulfillment && !isCopy;

  const openModal =
    query.openChecklistTemplate ||
    isAdd ||
    isEdit ||
    isFulfillment ||
    isCopy ||
    isReadOnly;

  const isAPConsole =
    window.document.URL.includes('above-property/checklists') &&
    isAboveProperty;

  const formatSelectedProperties = useCallback(
    (
      items: typeof properties,
      selectedItems: string[],
      formattedProperties: { title: string; value: string }[] = [],
    ) => {
      items.map(({ title, value, children }) => {
        if (selectedItems.includes(value)) {
          formattedProperties.push({
            title,
            value,
          });
        }
        return formatSelectedProperties(
          children as typeof properties,
          selectedProperties,
          formattedProperties,
        );
      });

      setAssociatedProperties(formattedProperties);
    },
    [selectedProperties],
  );

  useEffect(() => {
    if (isAboveProperty && isAPConsole) {
      formatSelectedProperties(properties, selectedProperties);
    }
  }, [
    properties,
    selectedProperties,
    formatSelectedProperties,
    isAPConsole,
    isAboveProperty,
  ]);

  const checklistContextValue = useMemo(
    () => ({
      close: closeChecklist,
      open: setOpen,
      openChild: setChildOpen,
      isOpen,
      isChildOpen,
      readOnly,
      isReadOnly,
      isAdd,
      isEdit,
      isCopy,
      isFulfillment,
      loading,
      setLoading,
      data,
      mappedCreatorIds,
      setData,
      query,
      setQuery,
      modalMode,
      properties,
      isAboveProperty,
      selectedProperties,
      associatedProperties,
      existingAPChecklists,
    }),
    [
      closeChecklist,
      setOpen,
      setChildOpen,
      isChildOpen,
      isOpen,
      readOnly,
      isReadOnly,
      isAdd,
      isEdit,
      isCopy,
      isFulfillment,
      loading,
      setLoading,
      data,
      setData,
      query,
      setQuery,
      modalMode,
      properties,
      isAboveProperty,
      selectedProperties,
      associatedProperties,
      mappedCreatorIds,
      existingAPChecklists,
    ],
  );

  return (
    <ChecklistModalContext.Provider value={checklistContextValue}>
      {modalMode ? (
        openModal && <Details />
      ) : (
        <SettingsMain {...{ modifiers: 'withMoreThanThreeTableColumns' }}>
          <SettingsMain.Header>
            <SettingsMain.Title>
              {t('checklists:Checklists')}
            </SettingsMain.Title>
            <AddChecklist
              onClick={() => {
                GoogleAnalyticsClient.event(GA_EVENTS.addCheckList);
                setIsAdd(true);
                setOpen(true);
              }}
              data-testid="add-checklist-template-btn"
            >
              {`${t('common:Add')} ${t('checklists:Checklist')}`}
            </AddChecklist>
          </SettingsMain.Header>
          <List />
          <Details />
        </SettingsMain>
      )}
    </ChecklistModalContext.Provider>
  );
}
