import { TabsProps } from 'antd';
import { COLORS, Flex, SPACING, Tabs } from '@optii-solutions/ui-library';
import { LegacyLog, TJobDetailsTabsData } from './JobDetails.types';
import { JobNotes } from '../JobNotes';
import { JOB_STATUSES } from '../JobForm/JobForm.constants';
import { JobTasks } from '../JobTasks';
import ActivityLog from './JobActivityLog';

const TABS = ['checklists', 'notes', 'activity'];

type TJobDetailsTabsProps = {
  data: TJobDetailsTabsData;
  t: (a: string) => string;
  jobRefetch: () => void;
  updateChecklistTask: (a: any) => void;
  isQueuedJob: boolean;
  legacyLogs: LegacyLog[];
  setActiveTab: (a: string) => void;
  activeTab: string;
};

export function JobDetailsTabs(props: TJobDetailsTabsProps) {
  const {
    data,
    jobRefetch,
    t,
    updateChecklistTask,
    isQueuedJob,
    legacyLogs,
    setActiveTab,
    activeTab,
  } = props;
  const { id, checklistTemplate, checklists, status } = data;

  const inProgress = data.status === JOB_STATUSES.inProgress.value;
  const ITEMS: TabsProps['items'] = [
    {
      key: '1',
      label: t('jobs:Tasks'),
      children: (
        <Flex
          style={{
            backgroundColor: COLORS.neutral[1],
            flex: 1,
          }}
          vertical
        >
          <JobTasks
            t={t}
            job={{
              checklistTemplate,
              checklists,
              status,
            }}
            updateChecklistTask={updateChecklistTask}
            canEdit={inProgress}
          />
        </Flex>
      ),
    },
    {
      key: '2',
      label: t('fields:Notes'),
      children: (
        <Flex
          style={{
            backgroundColor: COLORS.neutral[4],
            flex: 1,
          }}
        >
          <JobNotes
            data-testid="job-notes"
            job={{
              id: data.id,
              status: data.status,
              action: data.action,
              type: data.type,
              notes: data.notes,
            }}
            isQueuedJob={isQueuedJob}
            refetch={jobRefetch}
            updateJobLoading={false}
            setActiveTab={setActiveTab}
          />
        </Flex>
      ),
    },
    isQueuedJob
      ? {
          key: '',
          label: '',
        }
      : {
          key: '3',
          label: t('jobs:Activity'),
          destroyInactiveTabPane: true,
          children: (
            <Flex
              style={{
                backgroundColor: COLORS.neutral[4],
                flex: 1,
              }}
            >
              <ActivityLog jobId={id} legacyLogs={legacyLogs} />
            </Flex>
          ),
        },
  ];

  const tabsItems: TabsProps['items'] = ITEMS?.reduce(
    (previous: TabsProps['items'], current) => {
      const next = previous ? [...previous] : [];

      const tab = TABS[Number(current.key) - 1];

      const hasIndex = data.checklists
        ? data[tab as keyof typeof data]?.length
        : data.checklistTemplate?.length;

      if (hasIndex || current.key === '2' || current.key === '3') {
        next.push(current);
      }

      return next;
    },
    [],
  );

  return (
    <Tabs
      defaultActiveKey={activeTab || '1'}
      tabBarGutter={SPACING.SIZE_XL}
      items={tabsItems}
      tabBarStyle={{
        backgroundColor: COLORS.neutral[3],
        marginLeft: -SPACING.SIZE_XL,
        marginRight: -SPACING.SIZE_XL,
        paddingLeft: SPACING.SIZE_XL,
        paddingRight: SPACING.SIZE_XL,
      }}
      style={{
        backgroundColor: COLORS.neutral[1],
        marginLeft: -SPACING.SIZE_XL,
        marginRight: -SPACING.SIZE_XL,
        paddingBottom: SPACING.SIZE_XL,
        paddingLeft: SPACING.SIZE_XL,
        paddingRight: SPACING.SIZE_XL,
        flex: 1,
        alignItems: 'stretch',
      }}
    />
  );
}
