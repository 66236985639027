import { useEffect, useState, MouseEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  Button,
  Col,
  Drawer,
  Form,
  FormItem,
  HeaderActions,
  Row,
  SPACING,
  Typography,
  useForm,
  useWatch,
  Segmented,
  COLORS,
  Flex,
} from '@optii-solutions/ui-library';
import dayjs from 'dayjs';
import 'dayjs/plugin/utc';
import 'dayjs/plugin/timezone';
import { type SegmentedOptions } from 'antd/es/segmented';

import {
  GA_EVENTS,
  useAccess,
  useProductAccess,
  usePropertyTime,
} from '@optii/shared';
import { PERMISSIONS } from '@optii/shared/constants/permissions';
import {
  GET_JOBS_SETTINGS,
  GET_NEW_JOB_DEFAULTS,
} from '@optii/shared/queries/job';
import Priority from '@optii/shared/components/molecules/Priority';
import { ReactComponent as ProjectJobIcon } from '@optii/shared/images/svg/ico-project-job.svg';
import { ReactComponent as RepeatingJobIcon } from '@optii/shared/images/svg/ico-repeating-job.svg';
import GoogleAnalyticsClient from '@optii/shared/utils/GoogleAnalyticsClient';
import { RoomTypesHkDefaultConfigChecklistsTemplate } from '@optii/jobs/api/jobs';

import {
  AUTO_ASSIGNEE_ID,
  JOB_ADVANCED_STATUSES,
  JOB_METADATA,
  JOB_PRIORITY_LEVELS,
  JOB_STATUSES,
} from './JobForm.constants';
import { JobFormHousekeepingFields } from './JobFormHousekeepingFields';
import { JobFormRegularFields } from './JobFormRegularFields';
import type {
  TJobData,
  TJobDataItem,
  TJobFormMode,
  TJobTypes,
} from './JobForm.types';
import { JobFormSkeleton } from './JobForm.skeleton';
import {
  ActionTypeSelector,
  CustomStyles,
  EditTemplateConfirmation,
} from './JobForm.elements';
import { useJobForm } from './JobForm.hooks';
import { PRODUCT_ACCESS } from '../PredictiveDueTime/PredictiveDueTime.constants';
import { HistorySummary } from '../HistorySummary';
import { validateJobForm } from './JobForm.helpers';

type TJobFormCustomValues = {
  action: string;
  jobItemId: string;
  item: string;
  amount: string;
  location: string;
  department: string;
  role: string;
  jobType: string;
};

type TJobFormProps = {
  open: boolean;
  onClose: (jobId?: unknown) => void;
  mode?: TJobFormMode;
  customValues?: TJobFormCustomValues;
  propertyShard?: string;
};

export function JobForm(props: TJobFormProps) {
  const { open, onClose, mode, customValues, propertyShard } = props;

  const [showAdvancedFields, setShowAdvancedFields] = useState<boolean>(false);
  const [submittable, setSubmittable] = useState<boolean>(false);

  const [form] = useForm();
  const watchedValues = useWatch([], form);

  const { can } = useAccess();
  const { canProduct } = useProductAccess();
  const canAutoAssign = can(PERMISSIONS.jobs.autoassign)
  const { timezone } = usePropertyTime(null);

  const canHousekeepingJobs = can(PERMISSIONS.house_keeping.add_edit);
  const canProductHousekeeping = canProduct([PRODUCT_ACCESS.house_keeping]);

  const {
    // General
    loading,
    getConstants,
    getInitialValues,
    createLoading,
    updateRepeatingJobLoading,
    showEditConfirmation,
    setShowEditConfirmation,
    handleSubmit,
    locations,
    jobItems,
    // Edit
    job,
    audit,
    editLoading,
    jobRefetch,
  } = useJobForm({
    mode,
    form,
    onClose,
    propertyShard,
  });

  const {
    JOB_TYPES,
    ACTION_TYPE_OPTIONS,
    HOUSEKEEPING_ACTION_TYPE_OPTIONS,
    FORM_OPTIONS,
  } = getConstants();

  const { t } = useTranslation(['common', 'jobs'], { useSuspense: false });

  const { data: jobsSettingsData } = useQuery(GET_JOBS_SETTINGS, {
    context: {
      _shard: propertyShard,
    },
  });

  const customStyles = CustomStyles();

  const assetId =
    job?.metadata?.publicAttributes?.asset?.id ||
    job?.metadata?.publicAttributes?.asset?.Id;
  const values = useMemo(
    () =>
      job
        ? {
            ...job,
            assetId,
            attachments: job?.attachments,
          }
        : null,
    [assetId, job],
  );

  const isHousekeepingJob =
    watchedValues?.jobType === JOB_TYPES.housekeeping.value;

  const isProjectJob = watchedValues?.metadata?.source === JOB_METADATA.project;

  const initialValues = getInitialValues();

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(
        validateJobForm({
          previousValues: values,
          mode,
          FORM_OPTIONS,
          JOB_STATUSES,
          JOB_ADVANCED_STATUSES,
          AUTO_ASSIGNEE_ID,
          setSubmittable,
        }),
      )
      .catch((err) => {
        if (err.errorFields?.length === 0) {
          setSubmittable(true);
        } else setSubmittable(false);
      });

    FORM_OPTIONS.actionType =
      watchedValues &&
      ACTION_TYPE_OPTIONS?.map((_actionType) => ({
        ..._actionType,
        active: !!(watchedValues.actionType === _actionType.value),
      }));

    FORM_OPTIONS.housekeepingActionType =
      watchedValues &&
      HOUSEKEEPING_ACTION_TYPE_OPTIONS?.map((_actionType) => ({
        ..._actionType,
        active: !!(watchedValues.actionType === _actionType.value),
      }));
  }, [
    ACTION_TYPE_OPTIONS,
    FORM_OPTIONS,
    HOUSEKEEPING_ACTION_TYPE_OPTIONS,
    JOB_TYPES.housekeeping.value,
    form,
    isHousekeepingJob,
    isProjectJob,
    mode,
    values,
    values?.assetId,
    watchedValues,
  ]);

  const [getJobDefaults] = useLazyQuery(GET_NEW_JOB_DEFAULTS, {
    context: { _instance: 'node', _shard: propertyShard },
  });

  useEffect(() => {
    (async () => {
      const selectedLocation = watchedValues?.location
        ? locations?.find(
            (location: { roomTypeId: string; id: string }) =>
              location.id === watchedValues?.location[0],
          )?.id
        : undefined;
      const firstJobItem = watchedValues?.items
        ? jobItems.find(
            (jobItem: { id: string }) =>
              jobItem.id === watchedValues?.items[0]?.id,
          )?.displayName
        : '';

      if (
        isHousekeepingJob &&
        selectedLocation &&
        mode === 'add' &&
        watchedValues?.actionType
      ) {
        const { data } = await getJobDefaults({
          variables: {
            locationID: Number(selectedLocation),
            type: watchedValues?.jobType,
            action: watchedValues?.actionType,
            item: firstJobItem,
          },
        });
        const durationMin = data?.defaults?.durationMin;
        const creditValue = data?.defaults?.creditValue;
        const checklist = data?.defaults?.checklistsTemplate;
        const departmentId = data?.defaults?.department?.id;
        const roleId = data?.defaults?.role?.id;

        if (durationMin) {
          form.setFieldValue('durationMinutes', durationMin);
        }

        if (creditValue) {
          form.setFieldValue('creditValue', creditValue);
        }

        if (checklist) {
          form.setFieldValue(
            'checklist',
            checklist?.map(
              ({ id }: RoomTypesHkDefaultConfigChecklistsTemplate) => id,
            ),
          );
        }

        if (departmentId) {
          form.setFieldValue('department', departmentId);
        }

        if (roleId) {
          form.setFieldValue('role', roleId);
        }
      } else if (mode === 'add' && firstJobItem) {
        const { data } = await getJobDefaults({
          variables: {
            type: watchedValues?.jobType,
            action: watchedValues?.actionType,
            item: firstJobItem,
          },
        });
        const departmentId = data?.defaults?.department?.id;
        const roleId = data?.defaults?.role?.id;

        if (departmentId) {
          form.setFieldValue('department', departmentId);
        }

        if (roleId) {
          form.setFieldValue('role', roleId);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    form,
    getJobDefaults,
    isHousekeepingJob,
    // Having locations and jobItems as a dependency causes this useEffect to loop, removing it but we should figure out
    // why this is happening in the near future
    // jobItems
    // locations,
    mode,
    watchedValues?.actionType,
    watchedValues?.items,
    watchedValues?.jobType,
    watchedValues?.location,
  ]);

  const Fields =
    watchedValues?.jobType === JOB_TYPES.housekeeping.value
      ? JobFormHousekeepingFields
      : JobFormRegularFields;

  useMemo(() => {
    if (customValues) {
      form.setFieldsValue({
        actionType: customValues.action,
        items: [
          {
            id: customValues?.jobItemId || customValues.item,
            amount: customValues.amount,
          },
        ],
        location: customValues?.location ? [customValues?.location] : [],
        department: customValues?.department,
        role: customValues?.role,
        jobType: customValues?.jobType,
      });
    }
  }, [customValues, form]);

  return (
    <Drawer
      destroyOnClose
      width={675}
      open={open}
      onClose={onClose}
      id="job-form-drawer"
      title={
        <Row align="middle" justify="space-between" wrap={false}>
          {mode === 'editTemplate' ? (
            <Flex vertical gap={SPACING.SIZE_MD}>
              <Flex align="center" gap={SPACING.SIZE_SM}>
                {job?.jobTemplate && <Priority job={job.jobTemplate} />}

                <RepeatingJobIcon />
              </Flex>

              <Typography.Title
                level={3}
                style={{
                  marginTop: SPACING.NONE,
                  fontFamily: 'Montserrat',
                  maxHeight: '10vh',
                  overflowY: 'auto',
                }}
              >
                {job?.jobTemplate?.locations &&
                  `${job.jobTemplate.locations
                    .map(
                      (location: { longDisplayName: string }) =>
                        location.longDisplayName,
                    )
                    .join(', ')}: `}
                {job?.jobTemplate?.items &&
                  job.jobTemplate.items
                    .map(
                      (item: TJobDataItem) =>
                        `${item.name}${
                          item.amount && Number(item.amount) > 1
                            ? ` (${item.amount})`
                            : ''
                        }`,
                    )
                    .join(', ')}
              </Typography.Title>
            </Flex>
          ) : null}

          {mode === 'edit' ? (
            <Flex align="center" gap={SPACING.SIZE_MD}>
              <Flex align="center" gap={SPACING.SIZE_SM}>
                {job && <Priority job={job} />}
                {job?.metadata?.source === JOB_METADATA.project && (
                  <ProjectJobIcon />
                )}
                {job?.metadata?.source === JOB_METADATA.repeating && (
                  <RepeatingJobIcon />
                )}
              </Flex>

              <Typography.Title
                level={3}
                style={{
                  marginTop: SPACING.NONE,
                  fontFamily: 'Montserrat',
                }}
              >
                {t('jobs:Edit Job')} {job?.id && `#${job.id}`}
              </Typography.Title>
            </Flex>
          ) : null}

          {mode === 'add' ? (
            <Typography.Title
              level={3}
              style={{
                marginTop: SPACING.NONE,
                fontFamily: 'Montserrat',
              }}
            >
              {t('jobs:Add Job')}
            </Typography.Title>
          ) : null}
          <HeaderActions onClose={onClose} responsive />
        </Row>
      }
      footer={
        <Row
          align="middle"
          justify="end"
          wrap={false}
          gutter={SPACING.SIZE_MS}
          style={{
            paddingRight: SPACING.SIZE_XL,
          }}
        >
          <Col>
            <Button
              type="text"
              size="large"
              onClick={() => {
                onClose();

                if (mode === 'edit') {
                  GoogleAnalyticsClient.event(
                    GA_EVENTS.editJobModalCancelButton,
                  );
                }
              }}
            >
              {t('common:Cancel')}
            </Button>
          </Col>
          <Col>
            {mode === 'edit' ? (
              <Button
                type="primary"
                htmlType="submit"
                disabled={
                  loading ||
                  editLoading ||
                  !submittable ||
                  (isHousekeepingJob && !canProductHousekeeping) ||
                  (isHousekeepingJob && !canHousekeepingJobs)
                }
                loading={editLoading}
                onClick={() => form.submit()}
              >
                {editLoading ? t('common:Updating...') : t('common:Update')}
              </Button>
            ) : (
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                disabled={loading || createLoading || !submittable}
                loading={createLoading}
                onClick={() => form.submit()}
              >
                {createLoading ? t('common:Saving..') : t('common:Save')}
              </Button>
            )}
          </Col>
        </Row>
      }
    >
      {loading && mode ? (
        <JobFormSkeleton mode={mode} />
      ) : (
        <Form
          autoComplete="off"
          form={form}
          title="Form"
          clearOnDestroy
          scrollToFirstError
          initialValues={initialValues}
          onFinish={handleSubmit}
          theme={{
            token: {
              colorError: COLORS.red[7],
              colorErrorBorder: COLORS.red[7],
            },
          }}
        >
          {mode === 'edit' ? (
            <div
              style={{
                marginBottom: SPACING.SIZE_XL,
              }}
            >
              <HistorySummary job={job} audit={audit} />
            </div>
          ) : null}
          <FormItem<TJobData>
            name="jobType"
            rules={[
              {
                required: true,
                message: t('jobs:Required'),
              },
            ]}
            validateTrigger="onBlur"
          >
            {mode === 'editTemplate' && (
              <Segmented
                style={customStyles.Segmented}
                options={
                  FORM_OPTIONS.jobType?.filter(
                    (jobType) => jobType.value !== JOB_TYPES.housekeeping.value,
                  ) as SegmentedOptions
                }
                block
                onChange={(value) => {
                  form.setFieldsValue({
                    jobType: value,
                  });
                }}
              />
            )}

            {mode === 'edit' && (
              <Segmented
                style={customStyles.Segmented}
                options={FORM_OPTIONS.jobType as SegmentedOptions}
                block
                onChange={(value) => {
                  form.setFieldsValue({
                    jobType: value,
                  });
                }}
              />
            )}

            {mode === 'add' && (
              <Segmented
                style={customStyles.Segmented}
                options={FORM_OPTIONS.jobType as SegmentedOptions}
                block
                onClick={(event: MouseEvent<HTMLDivElement>) => {
                  const previousJobType = form.getFieldValue('jobType');
                  const eventTarget = event.target as HTMLDivElement;
                  const eventTargetLabel = eventTarget.innerText;
                  const jobTypesKeys = Object.keys(JOB_TYPES);

                  jobTypesKeys.forEach((jobTypeKeysItem) => {
                    const currentJobTypeLabel =
                      JOB_TYPES[jobTypeKeysItem as keyof typeof JOB_TYPES]
                        .label;
                    const currentJobTypeValue =
                      JOB_TYPES[jobTypeKeysItem as keyof typeof JOB_TYPES]
                        .value;

                    if (currentJobTypeLabel === eventTargetLabel) {
                      if (
                        previousJobType === JOB_TYPES.housekeeping.value &&
                        currentJobTypeValue !== JOB_TYPES.housekeeping.value
                      ) {
                        form.setFieldValue('actionType', null);
                        form.setFieldValue('assignee', canAutoAssign ? String(AUTO_ASSIGNEE_ID) : null);
                      }
                      
                      if (
                        previousJobType !== JOB_TYPES.housekeeping.value &&
                        currentJobTypeValue === JOB_TYPES.housekeeping.value
                      ) {
                        form.setFieldValue('actionType', null);
                        form.setFieldValue('assignee', null);
                      }
                    }
                  });
                }}
                onChange={(value) => {
                  const isGuestType = value === JOB_TYPES.guest.value;
                  const ONCHANGE_TODAY_TIME = dayjs().tz(timezone);

                  const onChangeDueTime = isGuestType
                    ? ONCHANGE_TODAY_TIME.add(
                        jobsSettingsData &&
                          jobsSettingsData.jobsSettings.guestRequestMinutes,
                        'minutes',
                      )
                    : ONCHANGE_TODAY_TIME.add(
                        jobsSettingsData &&
                          jobsSettingsData.jobsSettings.internalRequestMinutes,
                        'minutes',
                      );

                  form.setFieldsValue({
                    jobType: value,
                    priority: isGuestType
                      ? JOB_PRIORITY_LEVELS.high.value
                      : JOB_PRIORITY_LEVELS.medium.value,
                    dueDate: ONCHANGE_TODAY_TIME,
                    dailyDueTime: onChangeDueTime,
                    weeklyDueTime: onChangeDueTime,
                    monthlyDueTime: onChangeDueTime,
                    yearlyDueTime: onChangeDueTime,
                    dueTime: onChangeDueTime,
                    startDate: ONCHANGE_TODAY_TIME,
                    checklist: Array.isArray(watchedValues.checklist)
                      ? watchedValues?.checklist?.[0]
                      : watchedValues.checklist,
                  });

                  if (value === JOB_TYPES.housekeeping.value) {
                    form.setFieldsValue({
                      location: null,
                      asset: null,
                      checklist: watchedValues.checklist && [
                        watchedValues.checklist,
                      ],
                    });
                  }
                }}
              />
            )}
          </FormItem>

          <ActionTypeSelector
            form={form}
            formOptions={FORM_OPTIONS}
            watchedValues={watchedValues}
            jobTypes={JOB_TYPES as TJobTypes}
            t={t}
          />

          <Fields
            mode={mode}
            form={form}
            formOptions={FORM_OPTIONS}
            audit={audit}
            propertyShard={propertyShard}
            values={
              job
                ? {
                    ...job,
                    assetId,
                    attachments: [
                      ...(values.attachments?.length ? values.attachments : []),
                    ],
                  }
                : null
            }
            showAdvancedFields={mode === 'editTemplate' || showAdvancedFields}
            setShowAdvancedFields={setShowAdvancedFields}
            refetch={jobRefetch}
          />

          {mode === 'editTemplate' && showEditConfirmation && (
            <EditTemplateConfirmation
              hide={() => {
                setShowEditConfirmation(false);
              }}
              job={job}
              updateRepeatingJobLoading={updateRepeatingJobLoading}
              onEditRepeatingJob={() => handleSubmit(watchedValues)}
              values={watchedValues}
              t={t}
            />
          )}
        </Form>
      )}
    </Drawer>
  );
}
