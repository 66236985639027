import { Button, Typography } from '@optii-solutions/ui-library';
import { FONTS } from '@optii/ui-library';
import { MouseEventHandler, ReactNode } from 'react';

type SetOpenJob = (value?: string) => void;

type ButtonProps = {
  onClick?: MouseEventHandler<HTMLElement> | undefined;
  children: ReactNode;
};

function ViewJobButton({ onClick, children }: ButtonProps) {
  return (
    <Button type="default" onClick={onClick}>
      {children}
    </Button>
  );
}

function ViewUser({ userId, userName }: { userId: string; userName: string }) {
  return (
    <Typography.Link
      href="#"
      style={{
        fontSize: FONTS.small.size,
        letterSpacing: FONTS.small.letterSpacing,
      }}
    >
      {userName}
    </Typography.Link>
  );
}

const FULL_PATTERN = /#(\d+)|\[\[(\d+)@([^\]]+)\]\]|\[\[@([^\]]+)\]\]/g;

export default function handleMessageContent(
  message: string | null,
  setOpenJob: SetOpenJob,
) {
  if (message) {
    const parts: (string | JSX.Element)[] = [];
    let lastIndex = 0;

    message.replace(
      FULL_PATTERN,
      (match, jobNumber, userId, userName, channel, offset) => {
        parts.push(message.slice(lastIndex, offset));

        if (jobNumber) {
          parts.push(
            <ViewJobButton onClick={() => setOpenJob(jobNumber)} key={offset}>
              #{jobNumber}
            </ViewJobButton>,
          );
        } else if (channel) {
          parts.push(
            <ViewUser key={offset} userId={channel} userName={channel} />,
          );
        } else if (userId && userName) {
          parts.push(
            <ViewUser key={offset} userId={userId} userName={userName} />,
          );
        }

        lastIndex = offset + match.length;
        return match;
      },
    );
    parts.push(message.slice(lastIndex));

    return parts;
  }
  return message;
}
