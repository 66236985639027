import { COLORS, FONTS, SPACING } from '@optii/ui-library';
import { CSSProperties } from 'react';

export const SECTION_TITLE_STYLE: CSSProperties = {
  marginBottom: SPACING.SIZE_MD,
  fontWeight: '500',
  letterSpacing: FONTS.medium.letterSpacing,
  color: COLORS.neutral[8],
};

export const HEADER_FONT_STYLE: CSSProperties = {
  fontWeight: 500,
  fontFamily: 'Montserrat',
};
