import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Int64: { input: any; output: any };
  Map: { input: any; output: any };
  Any: { input: any; output: any };
  Upload: { input: any; output: any };
  MetadataAttr: { input: any; output: any };
};

export type Query = {
  EscalationDefNameExists: Scalars['Boolean']['output'];
  FindBaseSchedule?: Maybe<Schedule>;
  FindBaseScheduleBySingleDate?: Maybe<Schedule>;
  FindEmployeesByDepartment: List;
  FindIndividualSchedule: IndividualSchedule;
  FindSchedule?: Maybe<Schedule>;
  FindScheduleBySingleDate?: Maybe<Schedule>;
  FindScheduleEntry: ScheduleEntry;
  FindScheduleListBySingleDate?: Maybe<Array<Maybe<Schedule>>>;
  GetAllLocationTypes: Array<LocationType>;
  GetAllProducts: Array<AccessProduct>;
  GetAllRoomTypes: Array<RoomType>;
  GetChatEmployees: Array<Maybe<Scalars['Int']['output']>>;
  GetDepartmentById: Department;
  GetDoneJobsList: List;
  GetEmployeeByUserId: EmployeeUser;
  GetEmployeeState: SessionOnlineStatus;
  GetEmploymentTypeById: EmploymentType;
  GetFileById: File;
  GetFileByUUID: File;
  GetFilesList: Array<Maybe<File>>;
  GetImportById: Import;
  GetInitialJobList: List;
  GetInvite: Invite;
  GetJobAuditList: Array<Maybe<AuditRecord>>;
  GetJobById: Job;
  GetJobDefault?: Maybe<JobDefault>;
  GetJobEscalationById: JobEscalation;
  GetJobItemById?: Maybe<JobItem>;
  GetJobItemByName?: Maybe<JobItem>;
  GetJobItems: Array<Maybe<Scalars['String']['output']>>;
  GetJobsSettings: JobsSettings;
  GetLocationById?: Maybe<Location>;
  GetLocationsMenu: Array<Maybe<LocationMin>>;
  GetLocationTypeByCode?: Maybe<LocationType>;
  GetLocationTypeById?: Maybe<LocationType>;
  GetLocationTypeByName?: Maybe<LocationType>;
  GetOrganizationById: Organization;
  GetPropertyById: Property;
  GetReportsDashboards: List;
  GetRoleById: Role;
  GetRoomTypeByCode?: Maybe<RoomType>;
  GetRoomTypeById?: Maybe<RoomType>;
  GetRoomTypeByName?: Maybe<RoomType>;
  GetScheduleAndMetrics?: Maybe<SchedulesAndMetrics>;
  GetScheduleById: Schedule;
  GetScheduleMetrics?: Maybe<ScheduleMetrics>;
  GetTagsFromLocationById?: Maybe<Array<LocationTag>>;
  GetTwilioToken: Scalars['String']['output'];
  GetUserAccess: UserAccess;
  GetUserByEmail: User;
  GetUserById: User;
  GetUserByUsername: User;
  InspectJob: Job;
  ListDepartments: List;
  ListEmployees: List;
  ListEmploymentTypes: List;
  ListInvites: List;
  ListJobEscalationDefs: List;
  ListJobItems: List;
  ListJobs: List;
  ListLocationMinimal?: Maybe<Array<Maybe<LocationMinimal>>>;
  ListLocations: List;
  ListLocationsHierarchical: AllLocationsHierarchical;
  ListLocationTypes: List;
  ListOrganizations: Array<Maybe<Organization>>;
  ListPermissions: List;
  ListProperties: Array<Maybe<Property>>;
  ListRoles: List;
  ListRoomType: List;
  ListTagsFromLocationProperty?: Maybe<Array<AllLocationTag>>;
  ListTeamMembers: Array<TeamMemberDepartment>;
  ListUsers: List;
  UsersStats: UsersStats;
  VerfifyUserEmailByUserNameOrEmail: Scalars['String']['output'];
  VerifyUserEmail: Scalars['String']['output'];
  VerifyUserEmailV2: UserEmail;
  VerifyUserName: Scalars['String']['output'];
};

export type QueryEscalationDefNameExistsArgs = {
  name: Scalars['String']['input'];
};

export type QueryFindBaseScheduleArgs = {
  dateBegin: Scalars['Int']['input'];
  dateEnd: Scalars['Int']['input'];
  departmentId: Scalars['Int']['input'];
  states: Array<ScheduleState>;
};

export type QueryFindBaseScheduleBySingleDateArgs = {
  date: Scalars['Int']['input'];
  departmentId: Scalars['Int']['input'];
  states: Array<ScheduleState>;
};

export type QueryFindEmployeesByDepartmentArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  departmentID: Scalars['ID']['input'];
  filters?: InputMaybe<Scalars['Map']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};

export type QueryFindIndividualScheduleArgs = {
  dateBegin: Scalars['Int']['input'];
  dateEnd: Scalars['Int']['input'];
  employeeID: Scalars['Int64']['input'];
};

export type QueryFindScheduleArgs = {
  dateBegin: Scalars['Int']['input'];
  dateEnd: Scalars['Int']['input'];
  departmentId: Scalars['Int']['input'];
  states: Array<ScheduleState>;
};

export type QueryFindScheduleBySingleDateArgs = {
  date: Scalars['Int']['input'];
  departmentId: Scalars['Int']['input'];
  states: Array<ScheduleState>;
};

export type QueryFindScheduleEntryArgs = {
  date: Scalars['Int64']['input'];
  employeeID: Scalars['Int64']['input'];
  scheduleID: Scalars['Int64']['input'];
};

export type QueryFindScheduleListBySingleDateArgs = {
  date: Scalars['Int']['input'];
  departmentId: Scalars['Int']['input'];
  states: Array<ScheduleState>;
};

export type QueryGetAllLocationTypesArgs = {
  filters?: InputMaybe<Scalars['Map']['input']>;
};

export type QueryGetDepartmentByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetDoneJobsListArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['Map']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetEmployeeByUserIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetEmployeeStateArgs = {
  userID?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryGetEmploymentTypeByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetFileByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetFileByUuidArgs = {
  uuid: Scalars['String']['input'];
};

export type QueryGetImportByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetInitialJobListArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['Map']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetInviteArgs = {
  code: Scalars['String']['input'];
};

export type QueryGetJobAuditListArgs = {
  id: Scalars['ID']['input'];
  startedAt?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetJobByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetJobDefaultArgs = {
  action?: InputMaybe<JobAction>;
  item: Scalars['String']['input'];
};

export type QueryGetJobEscalationByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetJobItemByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetJobItemByNameArgs = {
  name: Scalars['String']['input'];
};

export type QueryGetLocationByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetLocationTypeByCodeArgs = {
  code: Scalars['String']['input'];
};

export type QueryGetLocationTypeByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetLocationTypeByNameArgs = {
  name: Scalars['String']['input'];
};

export type QueryGetOrganizationByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetPropertyByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetReportsDashboardsArgs = {
  folder?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetRoleByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetRoomTypeByCodeArgs = {
  code: Scalars['String']['input'];
};

export type QueryGetRoomTypeByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetRoomTypeByNameArgs = {
  name: Scalars['String']['input'];
};

export type QueryGetScheduleAndMetricsArgs = {
  date: Scalars['Int']['input'];
  departmentId: Scalars['Int']['input'];
  states: Array<ScheduleState>;
};

export type QueryGetScheduleByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetScheduleMetricsArgs = {
  date: Scalars['Int']['input'];
  departmentId: Scalars['Int']['input'];
  states: Array<ScheduleState>;
};

export type QueryGetTagsFromLocationByIdArgs = {
  locationId: Scalars['ID']['input'];
};

export type QueryGetTwilioTokenArgs = {
  device: Scalars['String']['input'];
  identity: Scalars['String']['input'];
};

export type QueryGetUserAccessArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetUserByEmailArgs = {
  email: Scalars['String']['input'];
};

export type QueryGetUserByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetUserByUsernameArgs = {
  username: Scalars['String']['input'];
};

export type QueryInspectJobArgs = {
  jobId: Scalars['String']['input'];
};

export type QueryListDepartmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['Map']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};

export type QueryListEmployeesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['Map']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};

export type QueryListEmploymentTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['Map']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};

export type QueryListInvitesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['Map']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};

export type QueryListJobEscalationDefsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['Map']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};

export type QueryListJobItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['Map']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};

export type QueryListJobsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['Map']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};

export type QueryListLocationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['Map']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryListLocationTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['Map']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryListPermissionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['Map']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};

export type QueryListRolesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['Map']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};

export type QueryListRoomTypeArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['Map']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryListTagsFromLocationPropertyArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryListTeamMembersArgs = {
  date: Scalars['Int64']['input'];
  departments?: InputMaybe<Array<Scalars['String']['input']>>;
  employees?: InputMaybe<Array<Scalars['String']['input']>>;
  filters?: InputMaybe<TeamMembersListFilters>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<Scalars['String']['input']>>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
};

export type QueryListUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['Map']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};

export type QueryVerfifyUserEmailByUserNameOrEmailArgs = {
  userNameOrEmail: Scalars['String']['input'];
};

export type QueryVerifyUserEmailArgs = {
  email: Scalars['String']['input'];
};

export type QueryVerifyUserEmailV2Args = {
  email: Scalars['String']['input'];
};

export type QueryVerifyUserNameArgs = {
  username: Scalars['String']['input'];
};

export enum ScheduleState {
  Copying = 'copying',
  Draft = 'draft',
  Published = 'published',
  Publishing = 'publishing',
  SentForApproval = 'sent_for_approval',
  Template = 'template',
}

export type Schedule = {
  dateBegin: Scalars['Int64']['output'];
  dateEnd: Scalars['Int64']['output'];
  departmentId: Scalars['Int']['output'];
  employmentTypesList: Array<Maybe<EmploymentType>>;
  id: Scalars['ID']['output'];
  isPublished: Scalars['Boolean']['output'];
  roles: Array<Maybe<ScheduleRole>>;
  state: ScheduleState;
};

export type EmploymentType = {
  displayName: Scalars['String']['output'];
  employmentCode: Scalars['String']['output'];
  externalReference: Scalars['String']['output'];
  hoursBetweenShifts: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  maxHoursPerDay: Scalars['Float']['output'];
  maxHoursPerWeek: Scalars['Float']['output'];
  minHoursPerDay: Scalars['Float']['output'];
  minHoursPerWeek: Scalars['Float']['output'];
  organisationId: Scalars['Int']['output'];
  organisationName: Scalars['String']['output'];
};

export type ScheduleRole = {
  employees: Array<Maybe<ScheduleEmployee>>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  totalHours: Scalars['Float']['output'];
};

export type ScheduleEmployee = {
  emailAddr: Scalars['String']['output'];
  employeeId: Scalars['Int64']['output'];
  employeeRoleId: Scalars['Int64']['output'];
  employmentTypeID: Scalars['Int']['output'];
  employmentTypeName: Scalars['String']['output'];
  entries: Array<Maybe<ScheduleEntry>>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  stateData: SessionOnlineStatus;
  totalHours: Scalars['Float']['output'];
  totalMealHours: Scalars['Float']['output'];
  userId: Scalars['Int64']['output'];
  userName: Scalars['String']['output'];
};

export type ScheduleEntry = {
  date: Scalars['Int64']['output'];
  departmentID: Scalars['Int64']['output'];
  employeeID: Scalars['Int64']['output'];
  employmentTypeID: Scalars['Int64']['output'];
  hours: Scalars['Float']['output'];
  hoursCalculated: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  isAvailabe: Scalars['Boolean']['output'];
  publishedFlag: Scalars['Boolean']['output'];
  roleID: Scalars['Int64']['output'];
  roleName: Scalars['String']['output'];
  timeStartSec: Scalars['Int64']['output'];
  timeStopSec: Scalars['Int64']['output'];
  userDatetime: Scalars['Int64']['output'];
};

export type SessionOnlineStatus = {
  employeeID: Scalars['Int64']['output'];
  loggedIn: Scalars['Boolean']['output'];
  online: Scalars['Boolean']['output'];
  working: Scalars['Boolean']['output'];
};

export type List = {
  edges?: Maybe<Array<Edge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type Edge = {
  cursor: Scalars['String']['output'];
  node: ListNode;
};

export type ListNode =
  | Department
  | EmployeeUser
  | EmploymentType
  | Invite
  | Job
  | JobDefault
  | JobEscalation
  | JobItem
  | Location
  | LocationType
  | Organization
  | Permission
  | Property
  | ReportsDashboard
  | Role
  | RoomType
  | Schedule
  | User;

export type Department = {
  departmentCode: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  externalReference: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  organisationId: Scalars['Int']['output'];
  organisationName: Scalars['String']['output'];
};

export type EmployeeUser = {
  id: Scalars['ID']['output'];
  locations?: Maybe<Array<Maybe<EmployeeLocations>>>;
  locationTags?: Maybe<Array<Maybe<EmployeeLocationsTags>>>;
  propertyDetails?: Maybe<Array<Maybe<EmployeePropertyInfo>>>;
  status: EmployeeStatus;
  userEmailAddr?: Maybe<Scalars['String']['output']>;
  userFirstName?: Maybe<Scalars['String']['output']>;
  userId: Scalars['Int']['output'];
  userLastName?: Maybe<Scalars['String']['output']>;
  userMobileNum?: Maybe<Scalars['String']['output']>;
  userName: Scalars['String']['output'];
  workingHours?: Maybe<Array<Maybe<EmployeeWorkingHours>>>;
};

export type EmployeeLocations = {
  locationId: Scalars['Int']['output'];
};

export type EmployeeLocationsTags = {
  tagName: Scalars['String']['output'];
};

export type EmployeePropertyInfo = {
  employTypeId: Scalars['Int']['output'];
  employTypeName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  propertyId: Scalars['Int']['output'];
  propertyName: Scalars['String']['output'];
  roles?: Maybe<Array<Maybe<EmployeeInfo>>>;
};

export type EmployeeInfo = {
  deptCode?: Maybe<Scalars['String']['output']>;
  deptId: Scalars['Int']['output'];
  deptName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  roleId: Scalars['Int']['output'];
  roleName: Scalars['String']['output'];
  roleStatus: EmployeeRoleStatus;
};

export enum EmployeeRoleStatus {
  Active = 'active',
  Archived = 'archived',
  Declined = 'declined',
  Hold = 'hold',
  Inactive = 'inactive',
  Invited = 'invited',
}

export enum EmployeeStatus {
  Active = 'active',
  Archived = 'archived',
  Declined = 'declined',
  New = 'new',
}

export type EmployeeWorkingHours = {
  credits?: Maybe<Scalars['Float']['output']>;
  endTime: Scalars['String']['output'];
  roles?: Maybe<Array<Maybe<EmployeeWorkingHoursRoles>>>;
  startTime: Scalars['String']['output'];
  weekDays: Array<Maybe<WeekDay>>;
};

export type EmployeeWorkingHoursRoles = {
  roleId: Scalars['Int']['output'];
};

export enum WeekDay {
  Friday = 'Friday',
  Monday = 'Monday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
  Thursday = 'Thursday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
}

export type Invite = {
  code: Scalars['String']['output'];
  emailAddr: Scalars['String']['output'];
  employTypeId: Scalars['Int']['output'];
  employTypeName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  mobileNum?: Maybe<Scalars['String']['output']>;
  orgId: Scalars['Int']['output'];
  orgName: Scalars['String']['output'];
  roles?: Maybe<Array<Maybe<InviteInfo>>>;
  sendNow: Scalars['Boolean']['output'];
  sendText: Scalars['Boolean']['output'];
  status: InviteStatus;
  userExists: Scalars['Boolean']['output'];
  userFirstName: Scalars['String']['output'];
  userId: Scalars['Int']['output'];
  userLastName: Scalars['String']['output'];
  userName?: Maybe<Scalars['String']['output']>;
};

export type InviteInfo = {
  deptID: Scalars['Int']['output'];
  deptName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  roleID: Scalars['Int']['output'];
  roleName: Scalars['String']['output'];
};

export enum InviteStatus {
  Errored = 'errored',
  New = 'new',
  Outdated = 'outdated',
  Pending = 'pending',
  Used = 'used',
  Archived = 'archived',
}

export type Job = {
  action?: Maybe<JobAction>;
  assignee?: Maybe<UserMin>;
  attachments?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  checklists?: Maybe<Array<Checklist>>;
  checklistTemplate?: Maybe<Array<ChecklistTemplate>>;
  completedAt?: Maybe<Scalars['Int64']['output']>;
  createdAt?: Maybe<Scalars['Int64']['output']>;
  creditValue?: Maybe<Scalars['Float']['output']>;
  department?: Maybe<DepartmentMin>;
  doBy: Scalars['Int64']['output'];
  durationMin?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  isEscalated: Scalars['Boolean']['output'];
  isRushed?: Maybe<Scalars['Boolean']['output']>;
  item: Scalars['String']['output'];
  items?: Maybe<Array<JobItemInfo>>;
  locations: Array<Maybe<LocationMin>>;
  metadata?: Maybe<JobMetadata>;
  notes?: Maybe<Array<Maybe<JobNote>>>;
  parentChecklistTask?: Maybe<ChecklistTask>;
  parentChecklistTaskId?: Maybe<Scalars['String']['output']>;
  parentJobId?: Maybe<Scalars['Int64']['output']>;
  periods: Array<Maybe<JobPeriod>>;
  predictiveDueTime?: Maybe<Scalars['Int64']['output']>;
  priority: JobPriority;
  reporter?: Maybe<UserMin>;
  role?: Maybe<RoleMin>;
  scheduleStartTimeAt?: Maybe<Scalars['Int64']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  startedAt?: Maybe<Scalars['Int64']['output']>;
  status: JobStatus;
  timeWindowEnd?: Maybe<Scalars['Int64']['output']>;
  timeWindowStart?: Maybe<Scalars['Int64']['output']>;
  type: JobType;
};

export enum JobAction {
  Check = 'check',
  Clean = 'clean',
  Correction = 'correction',
  Deliver = 'deliver',
  Departure = 'departure',
  Find = 'find',
  Fix = 'fix',
  Inspect = 'inspect',
  Inspection = 'inspection',
  Install = 'install',
  NoAction = 'noAction',
  Perform = 'perform',
  Remove = 'remove',
  Repair = 'repair',
  Replace = 'replace',
  Stayover = 'stayover',
  Test = 'test',
  Touchup = 'touchup',
  Turndown = 'turndown',
}

export type UserMin = {
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  userName: Scalars['String']['output'];
};

export type Checklist = {
  checklistTasks?: Maybe<Array<Maybe<ChecklistTask>>>;
  checklistTemplate?: Maybe<ChecklistTemplate>;
  checklistTemplateId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  hasUncompletedChecklistTask?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  jobId: Scalars['Int64']['output'];
  name: Scalars['String']['output'];
  Order: Scalars['Int']['output'];
  totalPointsComplete?: Maybe<Scalars['Float']['output']>;
  totalPointsIncomplete?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type ChecklistTask = {
  attachments?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  checklistId?: Maybe<Scalars['String']['output']>;
  childJobsIds?: Maybe<Array<Scalars['Int64']['output']>>;
  createdAt: Scalars['String']['output'];
  dataCheckboxChecked?: Maybe<Scalars['Boolean']['output']>;
  dataDropdownValue?: Maybe<Array<Scalars['String']['output']>>;
  dataNumerical?: Maybe<Scalars['Float']['output']>;
  dataOnOff?: Maybe<Scalars['Boolean']['output']>;
  dataPassError?: Maybe<Scalars['String']['output']>;
  dataTemperatureScale?: Maybe<Scalars['String']['output']>;
  dataTemperatureValue?: Maybe<Scalars['Float']['output']>;
  dataText?: Maybe<Scalars['String']['output']>;
  dataYesNo?: Maybe<Scalars['String']['output']>;
  dropdownOptions?: Maybe<Array<Scalars['String']['output']>>;
  fulfillmentAttachments?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  fulfillmentNote?: Maybe<Scalars['String']['output']>;
  groupId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  orderingValue: Scalars['String']['output'];
  parentTaksId?: Maybe<Scalars['String']['output']>;
  pointsComplete?: Maybe<Scalars['Float']['output']>;
  pointsIncomplete?: Maybe<Scalars['Float']['output']>;
  required: Scalars['Boolean']['output'];
  taskType: Scalars['String']['output'];
  templateTaskId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type ChecklistTemplate = {
  createdAt: Scalars['String']['output'];
  creator?: Maybe<ChecklistTemplateCreator>;
  creatorId: Scalars['Int64']['output'];
  description?: Maybe<Scalars['String']['output']>;
  enableScoring: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  Order: Scalars['Int']['output'];
  tasks?: Maybe<Array<Maybe<ChecklistTemplateTask>>>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type ChecklistTemplateCreator = {
  createdAt: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['Int64']['output'];
  lastName: Scalars['String']['output'];
};

export type ChecklistTemplateTask = {
  attachments?: Maybe<Array<Scalars['String']['output']>>;
  checklistTemplateId: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  dataTemperatureScale?: Maybe<Scalars['String']['output']>;
  defaultValue?: Maybe<ChecklistTemplateTaskDefaultValue>;
  dropdownOptions?: Maybe<Array<Scalars['String']['output']>>;
  groupId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  orderingValue: Scalars['String']['output'];
  parentTaksId?: Maybe<Scalars['String']['output']>;
  pointsComplete?: Maybe<Scalars['Float']['output']>;
  pointsIncomplete?: Maybe<Scalars['Float']['output']>;
  required: Scalars['Boolean']['output'];
  taskType: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type ChecklistTemplateTaskDefaultValue = {
  scale?: Maybe<Scalars['Any']['output']>;
  value?: Maybe<Scalars['Any']['output']>;
};

export type DepartmentMin = {
  departmentCode: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type JobItemInfo = {
  amount?: Maybe<Scalars['Int64']['output']>;
  jobId?: Maybe<Scalars['Int64']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type LocationMin = {
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  longDisplayName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  shortDisplayName?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type JobMetadata = {
  externalId?: Maybe<Scalars['String']['output']>;
  privateAttributes?: Maybe<Scalars['Map']['output']>;
  publicAttributes?: Maybe<Scalars['Map']['output']>;
  source?: Maybe<Scalars['String']['output']>;
};

export type JobNote = {
  createdBy?: Maybe<UserMin>;
  id?: Maybe<Scalars['ID']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['Int64']['output']>;
  updatedAt?: Maybe<Scalars['Int64']['output']>;
};

export type JobPeriod = {
  assignee?: Maybe<UserMin>;
  assigneeId?: Maybe<Scalars['Int64']['output']>;
  jobId: Scalars['Int64']['output'];
  periodEnd?: Maybe<Scalars['Int64']['output']>;
  periodStart?: Maybe<Scalars['Int64']['output']>;
  periodType: PeriodType;
  roleId?: Maybe<Scalars['Int64']['output']>;
};

export enum PeriodType {
  Completed = 'completed',
  InProgress = 'in_progress',
  NotStarted = 'not_started',
  Paused = 'paused',
}

export enum JobPriority {
  High = 'high',
  Highest = 'highest',
  Low = 'low',
  Medium = 'medium',
}

export type RoleMin = {
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export enum JobStatus {
  Archived = 'archived',
  Cancelled = 'cancelled',
  Completed = 'completed',
  InProgress = 'in_progress',
  New = 'new',
  OnHold = 'on_hold',
  Pending = 'pending',
}

export enum JobType {
  Guest = 'guest',
  Housekeeping = 'housekeeping',
  Internal = 'internal',
}

export type JobDefault = {
  action?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Int64']['output'];
  defaultTag: Scalars['String']['output'];
  department: DepartmentMin;
  id: Scalars['ID']['output'];
  item: Scalars['String']['output'];
  role?: Maybe<RoleMin>;
  updatedAt: Scalars['Int64']['output'];
};

export type JobEscalation = {
  Defs: Array<Maybe<JobEscalationDefs>>;
  Id: Scalars['ID']['output'];
  Name: Scalars['String']['output'];
  PropertyId: Scalars['ID']['output'];
  Rules: JobEscalationRules;
};

export type JobEscalationDefs = {
  ActionRules: EscalationRules;
  ActionType: Scalars['String']['output'];
  Id: Scalars['ID']['output'];
  RoleIds: Array<Maybe<Scalars['Int']['output']>>;
  ScheduleDef: SchedulerDef;
};

export type EscalationRules = {
  IsAssigned?: Maybe<Scalars['Boolean']['output']>;
};

export type SchedulerDef = {
  Duration: Scalars['String']['output'];
  OffsetType: Scalars['String']['output'];
};

export type JobEscalationRules = {
  Actions: Array<Maybe<Scalars['String']['output']>>;
  DepartmentIds: Array<Maybe<Scalars['Int']['output']>>;
  JobTypes: Array<Maybe<Scalars['String']['output']>>;
  Priorities: Array<Maybe<Scalars['String']['output']>>;
  RoleIds: Array<Maybe<Scalars['Int']['output']>>;
  Statuses?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type JobItem = {
  assignments?: Maybe<Array<JobItemAssignment>>;
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  readOnly: Scalars['Boolean']['output'];
};

export type JobItemAssignment = {
  action?: Maybe<Scalars['String']['output']>;
  department: DepartmentMin;
  role?: Maybe<RoleMin>;
};

export type Location = {
  associatedVirtualLocations: Array<AssociatedVirtualLocation>;
  childrenIds: Array<Scalars['Int64']['output']>;
  class?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  isVirtual: Scalars['Boolean']['output'];
  locationType: LocationType;
  longDisplayName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['Int64']['output']>;
  parentId?: Maybe<Scalars['ID']['output']>;
  parentLocation?: Maybe<Location>;
  roomType?: Maybe<RoomType>;
  sequence?: Maybe<Scalars['Float']['output']>;
  shortDisplayName: Scalars['String']['output'];
  squareUnit?: Maybe<Scalars['Float']['output']>;
  status: LocationStatus;
  tags?: Maybe<Array<LocationTag>>;
  virtualChildrenLocationIds: Array<Scalars['Int64']['output']>;
};

export type AssociatedVirtualLocation = {
  id: Scalars['ID']['output'];
  longDisplayName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  parentId: Scalars['ID']['output'];
  shortDisplayName: Scalars['String']['output'];
};

export type LocationType = {
  code?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
  squareUnit?: Maybe<Scalars['Float']['output']>;
};

export type RoomType = {
  code?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  hkDefaultConfigs?: Maybe<Array<Maybe<RoomTypesHkDefaultConfig>>>;
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
};

export type RoomTypesHkDefaultConfig = {
  checklistsTemplates?: Maybe<
    Array<Maybe<RoomTypesHkDefaultConfigChecklistsTemplate>>
  >;
  creditValue?: Maybe<Scalars['Float']['output']>;
  durationMin?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  type: RoomTypesHkDefaultConfigAction;
};

export type RoomTypesHkDefaultConfigChecklistsTemplate = {
  checklistTemplateId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export enum RoomTypesHkDefaultConfigAction {
  Check = 'Check',
  Correction = 'Correction',
  Departure = 'Departure',
  DepartureInspection = 'DepartureInspection',
  Stayover = 'Stayover',
  StayoverInspection = 'StayoverInspection',
  TouchUp = 'TouchUp',
  Turndown = 'Turndown',
}

export enum LocationStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export type LocationTag = {
  id: Scalars['ID']['output'];
  locationId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Organization = {
  code: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  parentId?: Maybe<Scalars['Int']['output']>;
  products?: Maybe<Array<AccessProduct>>;
  type: Scalars['String']['output'];
};

export enum AccessProduct {
  AbovePropertyControl = 'above_property_control',
  Communication = 'communication',
  Configuration = 'configuration',
  Engineering = 'engineering',
  HomeDashboard = 'home_dashboard',
  HouseKeeping = 'house_keeping',
  Jobs = 'jobs',
  PmsData = 'pms_data',
  Reports = 'reports',
  Schedule = 'schedule',
  ServiceDelivery = 'service_delivery',
  Settings = 'settings',
  SmartOperations = 'smart_operations',
  Translation = 'translation',
  UserProfile = 'user_profile',
}

export type Permission = {
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  isEnabled: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  product: AccessProduct;
};

export type Property = {
  billingContact?: Maybe<PropertyContact>;
  billingLocation?: Maybe<PropertyLocation>;
  budgetValue?: Maybe<Scalars['Float']['output']>;
  displayName: Scalars['String']['output'];
  externalRefCode?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  internalRefCode?: Maybe<Scalars['String']['output']>;
  licensedRoomCount: Scalars['Int']['output'];
  location?: Maybe<PropertyLocation>;
  mgmtCompanyId?: Maybe<Scalars['Int']['output']>;
  mgmtCompanyName?: Maybe<Scalars['String']['output']>;
  operationsContact?: Maybe<PropertyContact>;
  orgId?: Maybe<Scalars['Int']['output']>;
  orgName?: Maybe<Scalars['String']['output']>;
  ownerId?: Maybe<Scalars['Int']['output']>;
  ownerName?: Maybe<Scalars['String']['output']>;
  products?: Maybe<Array<AccessProduct>>;
  regionalSettings?: Maybe<RegionalSettings>;
  securitySettings?: Maybe<SecuritySettings>;
  status: PropertyStatus;
  useLocationOption: Scalars['Boolean']['output'];
};

export type PropertyContact = {
  ContactId: Scalars['ID']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phone: Scalars['String']['output'];
};

export type PropertyLocation = {
  address: Scalars['String']['output'];
  address2: Scalars['String']['output'];
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  LocationId: Scalars['ID']['output'];
  postalCode: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

export type RegionalSettings = {
  language: Scalars['String']['output'];
  logoURL: Scalars['String']['output'];
  RegionalSettingsId: Scalars['ID']['output'];
  startOfFiscalYear: Scalars['String']['output'];
  startOfWeek: Scalars['String']['output'];
  timeZone: Scalars['String']['output'];
};

export type SecuritySettings = {
  complexPassword: PasswordSettings;
  minUsernameChars: Scalars['Int']['output'];
  SecuritySettingsId: Scalars['ID']['output'];
  selectLoginSettings: Scalars['Boolean']['output'];
  simplePassword: PasswordSettings;
};

export type PasswordSettings = {
  forcePasswordChange: Scalars['Int']['output'];
  maskPassword: Scalars['Boolean']['output'];
  minPasswordChars: Scalars['Int']['output'];
  PasswordSettingsId: Scalars['ID']['output'];
  previousPasswordCnt: Scalars['Int']['output'];
  reqAlphaChars: Scalars['Boolean']['output'];
  reqNumbers: Scalars['Boolean']['output'];
  reqSpecialChars: Scalars['Boolean']['output'];
  sessionTimeout: Scalars['Int']['output'];
  unsuccessfulAttempts: Scalars['Int']['output'];
};

export enum PropertyStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export type ReportsDashboard = {
  Description: Scalars['String']['output'];
  OID: Scalars['String']['output'];
  Owner: Scalars['String']['output'];
  Title: Scalars['String']['output'];
};

export type Role = {
  accessibleDepts?: Maybe<Array<Maybe<AccessibleDepartments>>>;
  break?: Maybe<BreakRule>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  meals?: Maybe<BreakRule>;
  name: Scalars['String']['output'];
  payment?: Maybe<PaymentOption>;
  pii?: Maybe<PiiRule>;
  productAccess: Array<PurchasedProduct>;
};

export type AccessibleDepartments = {
  departmentId?: Maybe<Scalars['Int']['output']>;
  departmentName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isDefault?: Maybe<Scalars['Boolean']['output']>;
};

export type BreakRule = {
  duration: Scalars['Int']['output'];
  frequency: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  isRecurring: Scalars['Boolean']['output'];
  type: BreakRuleType;
};

export enum BreakRuleType {
  Meal = 'meal',
  Paid = 'paid',
}

export type PaymentOption = {
  aveRooms: Scalars['Int']['output'];
  doubletime: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  optionName: PaymentType;
  overtime: Scalars['Float']['output'];
  rate: Scalars['Float']['output'];
};

export enum PaymentType {
  Hourly = 'hourly',
  PerRoom = 'per_room',
  Salary = 'salary',
}

export type PiiRule = {
  address: Scalars['Boolean']['output'];
  email: Scalars['Boolean']['output'];
  firstName: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['Boolean']['output'];
  mobile: Scalars['Boolean']['output'];
  username: Scalars['Boolean']['output'];
};

export type PurchasedProduct = {
  id: Scalars['ID']['output'];
  isEnabled: Scalars['Boolean']['output'];
  modules: Array<Module>;
  permissions: Array<Permission>;
  product: AccessProduct;
};

export type Module = {
  id: Scalars['ID']['output'];
  isEnabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  permissions?: Maybe<Array<Permission>>;
};

export type User = {
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  emailAddr: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  mobileNum: Scalars['String']['output'];
  personalPhoto: Scalars['String']['output'];
  piiDisabled: Scalars['Boolean']['output'];
  postalCode: Scalars['String']['output'];
  preferredLang: Scalars['String']['output'];
  state: Scalars['String']['output'];
  status: UserStatus;
  userName: Scalars['String']['output'];
};

export enum UserStatus {
  Active = 'active',
  Archived = 'archived',
  Inactive = 'inactive',
  New = 'new',
  Pending = 'pending',
  Unsent = 'unsent',
}

export type PageInfo = {
  endCursor: Scalars['String']['output'];
  hasNextPage: Scalars['Boolean']['output'];
};

export type IndividualSchedule = {
  dateBegin: Scalars['Int64']['output'];
  dateEnd: Scalars['Int64']['output'];
  entries: Array<Maybe<ScheduleEntry>>;
  schedulesIncluded?: Maybe<Array<Scalars['Int64']['output']>>;
  totalHours: Scalars['Float']['output'];
};

export type File = {
  createdAt: Scalars['Int64']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  URL: Scalars['String']['output'];
  UUID: Scalars['String']['output'];
};

export type Import = {
  createdAt: Scalars['Int64']['output'];
  description: Scalars['String']['output'];
  errors: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastProcessed: Scalars['String']['output'];
  messages: Scalars['String']['output'];
  processed: Scalars['String']['output'];
  status: ImportStatus;
  type: ImportType;
  updatedAt: Scalars['Int64']['output'];
};

export enum ImportStatus {
  Created = 'created',
  Error = 'error',
  Finished = 'finished',
  Processing = 'processing',
}

export enum ImportType {
  Locations = 'locations',
}

export type AuditRecord = {
  action: Scalars['String']['output'];
  createdAt: Scalars['Int64']['output'];
  details?: Maybe<Scalars['String']['output']>;
  employee: EmployeeMin;
  id: Scalars['ID']['output'];
  itemId: Scalars['Int64']['output'];
  modifier1?: Maybe<Scalars['String']['output']>;
  modifier2?: Maybe<Scalars['String']['output']>;
  object: Scalars['String']['output'];
};

export type EmployeeMin = {
  employeeId: Scalars['Int64']['output'];
  employeeSelf: Scalars['String']['output'];
  employeeUserFirstName?: Maybe<Scalars['String']['output']>;
  employeeUserLastName?: Maybe<Scalars['String']['output']>;
  employeeUserName: Scalars['String']['output'];
};

export type JobsSettings = {
  defaultDepartmentId?: Maybe<Scalars['Int64']['output']>;
  guestRequestMinutes: Scalars['Int64']['output'];
  internalRequestMinutes: Scalars['Int64']['output'];
};

export type SchedulesAndMetrics = {
  metrics?: Maybe<Array<Maybe<MetricsMapEntry>>>;
  schedules?: Maybe<Array<Maybe<ScheduleMapEntry>>>;
};

export type MetricsMapEntry = {
  key: Scalars['String']['output'];
  value?: Maybe<ScheduleMetrics>;
};

export type ScheduleMetrics = {
  arrivals: Scalars['Int']['output'];
  CPOR: Scalars['Int']['output'];
  days: Array<Maybe<ScheduleDaysMetrics>>;
  departures: Scalars['Int']['output'];
  occupancy: Scalars['Int']['output'];
  teamNoShows: Scalars['Int']['output'];
  totalHours: Scalars['Float']['output'];
  totalScheduled: Scalars['Int']['output'];
};

export type ScheduleDaysMetrics = {
  date: Scalars['Int64']['output'];
  totalHours: Scalars['Float']['output'];
  totalScheduled: Scalars['Int']['output'];
};

export type ScheduleMapEntry = {
  key: Scalars['String']['output'];
  value?: Maybe<Schedule>;
};

export type UserAccess = {
  Access: UserAccessAccess;
  FirstName: Scalars['String']['output'];
  LastName: Scalars['String']['output'];
  UserId: Scalars['ID']['output'];
  UserName: Scalars['String']['output'];
};

export type UserAccessAccess = {
  Properties?: Maybe<Array<UserAccessProperty>>;
};

export type UserAccessProperty = {
  id: Scalars['ID']['output'];
  Name: Scalars['String']['output'];
  OrgId: Scalars['ID']['output'];
  OrgName: Scalars['String']['output'];
  Roles?: Maybe<Array<UserAccessRole>>;
};

export type UserAccessRole = {
  id: Scalars['ID']['output'];
  Name: Scalars['String']['output'];
  Permissions?: Maybe<Array<Scalars['String']['output']>>;
};

export type LocationMinimal = {
  childCount: Scalars['Int64']['output'];
  id: Scalars['ID']['output'];
  locationTypeCode?: Maybe<Scalars['String']['output']>;
  locationTypeId?: Maybe<Scalars['ID']['output']>;
  locationTypeName?: Maybe<Scalars['String']['output']>;
  longDisplayName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parentId?: Maybe<Scalars['ID']['output']>;
  parentName?: Maybe<Scalars['String']['output']>;
  roomTypeId?: Maybe<Scalars['ID']['output']>;
  roomTypeName?: Maybe<Scalars['String']['output']>;
  shortDisplayName?: Maybe<Scalars['String']['output']>;
};

export type AllLocationsHierarchical = {
  corruptedLocations?: Maybe<Array<LocationSimple>>;
  topLevelLocations?: Maybe<Array<LocationHierarchical>>;
};

export type LocationSimple = {
  associatedVirtualLocations: Array<AssociatedVirtualLocation>;
  childrenIds?: Maybe<Array<Scalars['Int64']['output']>>;
  class?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isVirtual: Scalars['Boolean']['output'];
  locationType?: Maybe<LocationTypeSimple>;
  longDisplayName: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['Int64']['output']>;
  parentId?: Maybe<Scalars['Int']['output']>;
  parentLocation?: Maybe<LocationSimple>;
  roomType?: Maybe<RoomTypeSimple>;
  sequence?: Maybe<Scalars['Float']['output']>;
  shortDisplayName: Scalars['String']['output'];
  squareUnit?: Maybe<Scalars['Float']['output']>;
  status: LocationStatus;
  tags?: Maybe<Array<LocationTagSimple>>;
  virtualChildrenLocationIds: Array<Scalars['Int64']['output']>;
};

export type LocationTypeSimple = {
  code?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  note?: Maybe<Scalars['String']['output']>;
  squareUnit?: Maybe<Scalars['Float']['output']>;
};

export type RoomTypeSimple = {
  code?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  note?: Maybe<Scalars['String']['output']>;
};

export type LocationTagSimple = {
  id: Scalars['Int']['output'];
  locationId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type LocationHierarchical = {
  associatedVirtualLocations: Array<AssociatedVirtualLocation>;
  children?: Maybe<Array<LocationHierarchical>>;
  childrenIds?: Maybe<Array<Scalars['Int64']['output']>>;
  class?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isVirtual: Scalars['Boolean']['output'];
  locationType?: Maybe<LocationTypeSimple>;
  name?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['Int64']['output']>;
  parentId?: Maybe<Scalars['Int']['output']>;
  parentLocation?: Maybe<LocationSimple>;
  roomType?: Maybe<RoomTypeSimple>;
  sequence?: Maybe<Scalars['Float']['output']>;
  squareUnit?: Maybe<Scalars['Float']['output']>;
  status: LocationStatus;
  tags?: Maybe<Array<LocationTagSimple>>;
  virtualChildrenLocationIds: Array<Scalars['Int64']['output']>;
};

export type AllLocationTag = {
  name: Scalars['String']['output'];
};

export type TeamMembersListFilters = {
  loggedInOnly?: InputMaybe<Scalars['Boolean']['input']>;
  onlineOnly?: InputMaybe<Scalars['Boolean']['input']>;
  workingOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TeamMemberDepartment = {
  departmentCode: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  roles: Array<Maybe<ScheduleRole>>;
};

export type UsersStats = {
  active?: Maybe<Scalars['Float']['output']>;
  archived?: Maybe<Scalars['Float']['output']>;
  inactive?: Maybe<Scalars['Float']['output']>;
  new?: Maybe<Scalars['Float']['output']>;
  pending?: Maybe<Scalars['Float']['output']>;
  unsent?: Maybe<Scalars['Float']['output']>;
};

export type UserEmail = {
  employee?: Maybe<Scalars['String']['output']>;
  invitation?: Maybe<Scalars['String']['output']>;
  user?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  acceptInvite: SimpleId;
  addJob: Job;
  addJobItem: SimpleId;
  addScheduleEmployeesList: SimpleId;
  changePassword: SimpleId;
  copySchedule: SimpleId;
  createDepartment: SimpleId;
  createEmploymentType: SimpleId;
  createJobEscalation: SimpleId;
  createLocation: SimpleId;
  createLocationType: SimpleId;
  createOrganization?: Maybe<Organization>;
  createProperty?: Maybe<Property>;
  createRole: SimpleId;
  createRoomType: SimpleId;
  createSchedule: SimpleId;
  createScheduleEntry: ScheduleEntry;
  deleteDepartment: SimpleId;
  deleteEmploymentType: SimpleId;
  deleteInvite: SimpleId;
  deleteJobEscalation: SimpleId;
  deleteJobItem: SimpleId;
  deleteLocation: SimpleId;
  deleteLocationType: SimpleId;
  deleteMultiEntries: Array<Maybe<SimpleId>>;
  deleteOrganization: SimpleId;
  deleteRole: SimpleId;
  deleteRoomType: SimpleId;
  deleteSchedule: SimpleId;
  deleteScheduleEmployee: SimpleId;
  deleteScheduleEntry: SimpleId;
  editMultiEntries: Array<Maybe<ScheduleEntry>>;
  importLocations: SimpleId;
  inviteUser: Invite;
  moveLocations: Location;
  publishSchedule: SimpleId;
  registerDevice: Scalars['String']['output'];
  removeEmployee: SimpleId;
  saveInspectionFailedResult: Job;
  saveInspectionSuccessResult: Job;
  setEmployeeLoggedInState: SessionOnlineStatus;
  setEmployeeOnlineState: SessionOnlineStatus;
  setEmployeeWorkingState: SessionOnlineStatus;
  setJobsSettings: JobsSettings;
  storeFile: File;
  updateDepartment?: Maybe<Department>;
  updateEmployeeByUserId?: Maybe<EmployeeUser>;
  updateEmploymentType?: Maybe<EmploymentType>;
  updateInvite?: Maybe<Invite>;
  updateJobById: Job;
  updateJobEscalation: JobEscalation;
  updateJobItemById: JobItem;
  updateJobMetadata?: Maybe<SimpleId>;
  updateLocation?: Maybe<Location>;
  updateLocationType: LocationType;
  updateMultipleJobs: Array<Maybe<Job>>;
  updateOrganization?: Maybe<Organization>;
  updateProperty?: Maybe<Property>;
  updateRole?: Maybe<Role>;
  updateRoomType: RoomType;
  updateScheduleEntry: ScheduleEntry;
  updateUser?: Maybe<User>;
};

export type MutationAcceptInviteArgs = {
  input: UpdateUser;
  inviteCode: Scalars['String']['input'];
};

export type MutationAddJobArgs = {
  input?: InputMaybe<JobInput>;
};

export type MutationAddJobItemArgs = {
  input: JobItemInput;
};

export type MutationAddScheduleEmployeesListArgs = {
  input: Array<InputMaybe<CreateScheduleEmployee>>;
  scheduleId: Scalars['ID']['input'];
};

export type MutationChangePasswordArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<UpdateUserPassword>;
};

export type MutationCopyScheduleArgs = {
  dateBegin: Scalars['Int64']['input'];
  dateEnd: Scalars['Int64']['input'];
  id: Scalars['ID']['input'];
};

export type MutationCreateDepartmentArgs = {
  input: CreateDepartment;
};

export type MutationCreateEmploymentTypeArgs = {
  input: CreateEmploymentType;
};

export type MutationCreateJobEscalationArgs = {
  escalation?: InputMaybe<JobEscalationInput>;
};

export type MutationCreateLocationArgs = {
  input: CreateLocation;
};

export type MutationCreateLocationTypeArgs = {
  input: CreateLocationType;
};

export type MutationCreateOrganizationArgs = {
  input: NewOrganization;
};

export type MutationCreatePropertyArgs = {
  input: NewProperty;
};

export type MutationCreateRoleArgs = {
  input: CreateRole;
};

export type MutationCreateRoomTypeArgs = {
  input: CreateRoomType;
};

export type MutationCreateScheduleArgs = {
  input: CreateSchedule;
};

export type MutationCreateScheduleEntryArgs = {
  input: CreateScheduleEntry;
};

export type MutationDeleteDepartmentArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteEmploymentTypeArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteInviteArgs = {
  inviteCode: Scalars['String']['input'];
};

export type MutationDeleteJobEscalationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteJobItemArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteLocationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteLocationTypeArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteMultiEntriesArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type MutationDeleteOrganizationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteRoleArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteRoomTypeArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteScheduleArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteScheduleEmployeeArgs = {
  id: Scalars['ID']['input'];
  scheduleId: Scalars['ID']['input'];
};

export type MutationDeleteScheduleEntryArgs = {
  id: Scalars['ID']['input'];
};

export type MutationEditMultiEntriesArgs = {
  input: Array<InputMaybe<CreateScheduleEntry>>;
};

export type MutationImportLocationsArgs = {
  fileURL: Scalars['String']['input'];
};

export type MutationInviteUserArgs = {
  input: InviteUser;
};

export type MutationMoveLocationsArgs = {
  locationsIDs: Array<Scalars['ID']['input']>;
  parentID: Scalars['ID']['input'];
};

export type MutationPublishScheduleArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRegisterDeviceArgs = {
  address: Scalars['String']['input'];
  bindingType: Scalars['String']['input'];
  tags: Array<InputMaybe<Scalars['String']['input']>>;
};

export type MutationRemoveEmployeeArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSaveInspectionFailedResultArgs = {
  job?: InputMaybe<CreateCorrectionJobInput>;
  jobId: Scalars['String']['input'];
};

export type MutationSaveInspectionSuccessResultArgs = {
  jobId: Scalars['String']['input'];
};

export type MutationSetEmployeeLoggedInStateArgs = {
  loggedIn: Scalars['Boolean']['input'];
};

export type MutationSetEmployeeOnlineStateArgs = {
  online: Scalars['Boolean']['input'];
};

export type MutationSetEmployeeWorkingStateArgs = {
  working: Scalars['Boolean']['input'];
};

export type MutationSetJobsSettingsArgs = {
  input: JobsSettingsInput;
};

export type MutationStoreFileArgs = {
  file: Scalars['Upload']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateDepartmentArgs = {
  id: Scalars['ID']['input'];
  input: UpdateDepartment;
};

export type MutationUpdateEmployeeByUserIdArgs = {
  id: Scalars['ID']['input'];
  input: EmployeeUpdate;
};

export type MutationUpdateEmploymentTypeArgs = {
  id: Scalars['ID']['input'];
  input: UpdateEmploymentType;
};

export type MutationUpdateInviteArgs = {
  input: UpdateInvite;
  inviteCode: Scalars['String']['input'];
};

export type MutationUpdateJobByIdArgs = {
  id: Scalars['ID']['input'];
  input: JobEdit;
};

export type MutationUpdateJobEscalationArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<JobEscalationInput>;
};

export type MutationUpdateJobItemByIdArgs = {
  id: Scalars['ID']['input'];
  input: JobItemInput;
};

export type MutationUpdateJobMetadataArgs = {
  id: Scalars['ID']['input'];
  input: MetadataEdit;
  schema: Scalars['String']['input'];
};

export type MutationUpdateLocationArgs = {
  id: Scalars['ID']['input'];
  input: UpdateLocation;
};

export type MutationUpdateLocationTypeArgs = {
  id: Scalars['ID']['input'];
  input: UpdateLocationType;
};

export type MutationUpdateMultipleJobsArgs = {
  input: Array<JobEdit>;
};

export type MutationUpdateOrganizationArgs = {
  id: Scalars['ID']['input'];
  input: OrganizationUpdate;
};

export type MutationUpdatePropertyArgs = {
  id: Scalars['ID']['input'];
  input: PropertyUpdate;
};

export type MutationUpdateRoleArgs = {
  id: Scalars['ID']['input'];
  input: UpdateRole;
};

export type MutationUpdateRoomTypeArgs = {
  id: Scalars['ID']['input'];
  input: UpdateRoomType;
};

export type MutationUpdateScheduleEntryArgs = {
  id: Scalars['ID']['input'];
  input: UpdateScheduleEntry;
};

export type MutationUpdateUserArgs = {
  id: Scalars['ID']['input'];
  input: UpdateUser;
};

export type UpdateUser = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  emailAddr?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mobileNum?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  personalPhoto?: InputMaybe<Scalars['String']['input']>;
  piiDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  preferredLang?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
};

export type SimpleId = {
  id: Scalars['ID']['output'];
  status?: Maybe<Scalars['String']['output']>;
};

export type JobInput = {
  action?: InputMaybe<JobAction>;
  assignee?: InputMaybe<UserMinInput>;
  attachments?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  checklistItems?: InputMaybe<Array<InputMaybe<ChecklistItemInput>>>;
  checklistTemplates?: InputMaybe<Array<InputMaybe<ChecklistTemplateInput>>>;
  creditValue?: InputMaybe<Scalars['Float']['input']>;
  department: DepartmentMinInput;
  doBy: Scalars['Int64']['input'];
  durationMin?: InputMaybe<Scalars['Int']['input']>;
  isEscalated?: InputMaybe<Scalars['Boolean']['input']>;
  isRushed?: InputMaybe<Scalars['Boolean']['input']>;
  item?: InputMaybe<Scalars['String']['input']>;
  items?: InputMaybe<Array<JobItemInfoInput>>;
  locations: Array<InputMaybe<LocationMinInput>>;
  metadata?: InputMaybe<MetadataEdit>;
  note?: InputMaybe<Scalars['String']['input']>;
  parentJobId?: InputMaybe<Scalars['Int64']['input']>;
  predictiveDueTime?: InputMaybe<Scalars['Int64']['input']>;
  priority: JobPriority;
  reporter?: InputMaybe<UserMinInput>;
  role?: InputMaybe<RoleMinInput>;
  scheduleStartTimeAt?: InputMaybe<Scalars['Int64']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  timeWindowEnd?: InputMaybe<Scalars['Int64']['input']>;
  timeWindowStart?: InputMaybe<Scalars['Int64']['input']>;
  type: JobType;
};

export type UserMinInput = {
  firstName: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  lastName: Scalars['String']['input'];
  userName: Scalars['String']['input'];
};

export type ChecklistItemInput = {
  attachment?: InputMaybe<ChecklistAttachmentInput>;
  attachmentId?: InputMaybe<Scalars['String']['input']>;
  dataCheckboxChecked?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  itemType: Scalars['String']['input'];
  jobId: Scalars['Int64']['input'];
  label: Scalars['String']['input'];
  order: Scalars['Int']['input'];
};

export type ChecklistAttachmentInput = {
  data?: InputMaybe<Scalars['String']['input']>;
  dataType: Scalars['String']['input'];
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type ChecklistTemplateInput = {
  id: Scalars['String']['input'];
  order: Scalars['Int']['input'];
};

export type DepartmentMinInput = {
  departmentCode: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

export type JobItemInfoInput = {
  amount: Scalars['Int64']['input'];
  jobId?: InputMaybe<Scalars['Int64']['input']>;
  name: Scalars['String']['input'];
};

export type LocationMinInput = {
  id: Scalars['ID']['input'];
  longDisplayName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  shortDisplayName?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};

export type MetadataEdit = {
  externalId?: InputMaybe<Scalars['String']['input']>;
  privateAttributes?: InputMaybe<Scalars['Map']['input']>;
  publicAttributes?: InputMaybe<Scalars['Map']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
};

export type RoleMinInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type JobItemInput = {
  assignments?: InputMaybe<Array<InputMaybe<JobItemAssignmentInput>>>;
  displayName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  readOnly: Scalars['Boolean']['input'];
};

export type JobItemAssignmentInput = {
  action?: InputMaybe<Scalars['String']['input']>;
  department: DepartmentMinInput;
  role?: InputMaybe<RoleMinInput>;
};

export type CreateScheduleEmployee = {
  employeeID: Scalars['Int']['input'];
  employmentTypeID: Scalars['Int']['input'];
  employmentTypeName: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  roleID: Scalars['Int']['input'];
  roleName: Scalars['String']['input'];
  userID: Scalars['Int']['input'];
  userName: Scalars['String']['input'];
};

export type UpdateUserPassword = {
  newPassword?: InputMaybe<Scalars['String']['input']>;
  oldPassword?: InputMaybe<Scalars['String']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDepartment = {
  departmentCode: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
  externalReference: Scalars['String']['input'];
  organisationId: Scalars['Int']['input'];
  organisationName: Scalars['String']['input'];
};

export type CreateEmploymentType = {
  displayName: Scalars['String']['input'];
  employmentCode: Scalars['String']['input'];
  externalReference: Scalars['String']['input'];
  hoursBetweenShifts: Scalars['Float']['input'];
  maxHoursPerDay: Scalars['Float']['input'];
  maxHoursPerWeek: Scalars['Float']['input'];
  minHoursPerDay: Scalars['Float']['input'];
  minHoursPerWeek: Scalars['Float']['input'];
  organisationId: Scalars['Int']['input'];
  organisationName: Scalars['String']['input'];
};

export type JobEscalationInput = {
  Defs: Array<InputMaybe<JobEscalationDefsInput>>;
  Name: Scalars['String']['input'];
  PropertyId: Scalars['ID']['input'];
  Rules: JobEscalationRulesInput;
};

export type JobEscalationDefsInput = {
  ActionRules: EscalationRulesInput;
  ActionType: Scalars['String']['input'];
  Id: Scalars['ID']['input'];
  RoleIds: Array<InputMaybe<Scalars['Int']['input']>>;
  ScheduleDef: SchedulerDefInput;
};

export type EscalationRulesInput = {
  IsAssigned?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SchedulerDefInput = {
  Duration: Scalars['String']['input'];
  OffsetType: Scalars['String']['input'];
};

export type JobEscalationRulesInput = {
  Actions: Array<InputMaybe<Scalars['String']['input']>>;
  DepartmentIds: Array<InputMaybe<Scalars['Int']['input']>>;
  JobTypes: Array<InputMaybe<Scalars['String']['input']>>;
  Priorities: Array<InputMaybe<Scalars['String']['input']>>;
  RoleIds: Array<InputMaybe<Scalars['Int']['input']>>;
  Statuses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CreateLocation = {
  description?: InputMaybe<Scalars['String']['input']>;
  isActive: Scalars['Boolean']['input'];
  isVirtual?: InputMaybe<Scalars['Boolean']['input']>;
  locationTypeId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['Int64']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
  roomTypeId?: InputMaybe<Scalars['ID']['input']>;
  sequence?: InputMaybe<Scalars['Float']['input']>;
  squareUnit?: InputMaybe<Scalars['Float']['input']>;
  tags?: InputMaybe<Array<InputMaybe<UpsertLocationTag>>>;
  virtualChildrenLocationIds?: InputMaybe<
    Array<InputMaybe<Scalars['Int64']['input']>>
  >;
};

export type UpsertLocationTag = {
  name: Scalars['String']['input'];
};

export type CreateLocationType = {
  code?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  squareUnit?: InputMaybe<Scalars['Float']['input']>;
};

export type NewOrganization = {
  code: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['Int']['input']>;
  products?: InputMaybe<Array<AccessProduct>>;
  type: Scalars['String']['input'];
};

export type NewProperty = {
  displayName: Scalars['String']['input'];
  licensedRoomCount: Scalars['Int']['input'];
  mgmtCompanyId?: InputMaybe<Scalars['Int']['input']>;
  mgmtCompanyName?: InputMaybe<Scalars['String']['input']>;
  orgId?: InputMaybe<Scalars['Int']['input']>;
  orgName?: InputMaybe<Scalars['String']['input']>;
  ownerId?: InputMaybe<Scalars['Int']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  timeZone: Scalars['String']['input'];
};

export type CreateRole = {
  accessibleDepts?: InputMaybe<Array<InputMaybe<CreateAccessibleDepartments>>>;
  break?: InputMaybe<CreateBreakRule>;
  description?: InputMaybe<Scalars['String']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  meals?: InputMaybe<CreateBreakRule>;
  name: Scalars['String']['input'];
  payment?: InputMaybe<CreatePaymentOption>;
  pii?: InputMaybe<CreatePiiRule>;
  productAccess?: InputMaybe<Array<InputMaybe<CreatePurchasedProduct>>>;
};

export type CreateAccessibleDepartments = {
  departmentId: Scalars['Int']['input'];
  departmentName: Scalars['String']['input'];
};

export type CreateBreakRule = {
  duration?: InputMaybe<Scalars['Int']['input']>;
  frequency?: InputMaybe<Scalars['Int']['input']>;
  isRecurring: Scalars['Boolean']['input'];
};

export type CreatePaymentOption = {
  aveRooms?: InputMaybe<Scalars['Int']['input']>;
  doubletime?: InputMaybe<Scalars['Float']['input']>;
  optionName: PaymentType;
  overtime?: InputMaybe<Scalars['Float']['input']>;
  rate?: InputMaybe<Scalars['Float']['input']>;
};

export type CreatePiiRule = {
  address: Scalars['Boolean']['input'];
  email: Scalars['Boolean']['input'];
  firstName: Scalars['Boolean']['input'];
  lastName: Scalars['Boolean']['input'];
  mobile: Scalars['Boolean']['input'];
  username: Scalars['Boolean']['input'];
};

export type CreatePurchasedProduct = {
  modules?: InputMaybe<Array<ModuleRelation>>;
  permissions?: InputMaybe<Array<PermissionRelation>>;
  product: AccessProduct;
};

export type ModuleRelation = {
  id: Scalars['ID']['input'];
};

export type PermissionRelation = {
  id: Scalars['ID']['input'];
};

export type CreateRoomType = {
  code?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
  hkDefaultConfigs?: InputMaybe<
    Array<InputMaybe<RoomTypesHkDefaultConfigInput>>
  >;
  note?: InputMaybe<Scalars['String']['input']>;
};

export type RoomTypesHkDefaultConfigInput = {
  checklistsTemplates?: InputMaybe<
    Array<InputMaybe<RoomTypesHkDefaultConfigChecklistsTemplateInput>>
  >;
  creditValue?: InputMaybe<Scalars['Float']['input']>;
  durationMin?: InputMaybe<Scalars['Int']['input']>;
  type: RoomTypesHkDefaultConfigAction;
};

export type RoomTypesHkDefaultConfigChecklistsTemplateInput = {
  checklistTemplateId: Scalars['String']['input'];
};

export type CreateSchedule = {
  dateBegin: Scalars['Int64']['input'];
  dateEnd: Scalars['Int64']['input'];
  departmentId: Scalars['Int']['input'];
  employees: Array<InputMaybe<CreateScheduleEmployee>>;
};

export type CreateScheduleEntry = {
  date: Scalars['Int64']['input'];
  departmentID: Scalars['Int64']['input'];
  employeeID: Scalars['Int64']['input'];
  employmentTypeID: Scalars['Int64']['input'];
  employmentTypeName: Scalars['String']['input'];
  isAllday: Scalars['Boolean']['input'];
  isSplitShift: Scalars['Boolean']['input'];
  roleID: Scalars['Int64']['input'];
  roleName: Scalars['String']['input'];
  scheduleID: Scalars['Int64']['input'];
  splitStartSec?: InputMaybe<Scalars['Int']['input']>;
  splitStopSec?: InputMaybe<Scalars['Int']['input']>;
  timeStartSec?: InputMaybe<Scalars['Int']['input']>;
  timeStopSec?: InputMaybe<Scalars['Int']['input']>;
  userDatetime: Scalars['Int64']['input'];
};

export type InviteUser = {
  emailAddr: Scalars['String']['input'];
  employTypeId: Scalars['Int']['input'];
  employTypeName: Scalars['String']['input'];
  mobileNum?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['Int']['input'];
  orgName: Scalars['String']['input'];
  roles?: InputMaybe<Array<InputMaybe<InviteInfoUpdate>>>;
  sendNow: Scalars['Boolean']['input'];
  sendText: Scalars['Boolean']['input'];
  userExists?: InputMaybe<Scalars['Boolean']['input']>;
  userFirstName: Scalars['String']['input'];
  userLastName: Scalars['String']['input'];
};

export type InviteInfoUpdate = {
  deptID: Scalars['Int']['input'];
  deptName: Scalars['String']['input'];
  roleID: Scalars['Int']['input'];
  roleName: Scalars['String']['input'];
};

export type CreateCorrectionJobInput = {
  durationInSeconds: Scalars['Int']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  skipCheck: Scalars['Boolean']['input'];
};

export type JobsSettingsInput = {
  defaultDepartmentId: Scalars['Int64']['input'];
  guestRequestMinutes: Scalars['Int64']['input'];
  internalRequestMinutes: Scalars['Int64']['input'];
};

export type UpdateDepartment = {
  departmentCode?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  externalReference?: InputMaybe<Scalars['String']['input']>;
  organisationId?: InputMaybe<Scalars['Int']['input']>;
  organisationName?: InputMaybe<Scalars['String']['input']>;
};

export type EmployeeUpdate = {
  locations?: InputMaybe<Array<InputMaybe<EmployeeLocationsUpdate>>>;
  locationTags?: InputMaybe<Array<InputMaybe<EmployeeLocationsTagsUpdate>>>;
  propertyDetails?: InputMaybe<Array<InputMaybe<EmployeePropertiesUpdate>>>;
  status?: InputMaybe<EmployeeStatus>;
  userFirstName?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
  userLastName?: InputMaybe<Scalars['String']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
  workingHours?: InputMaybe<Array<InputMaybe<EmployeeWorkingHoursUpdate>>>;
};

export type EmployeeLocationsUpdate = {
  locationId: Scalars['Int']['input'];
};

export type EmployeeLocationsTagsUpdate = {
  tagName: Scalars['String']['input'];
};

export type EmployeePropertiesUpdate = {
  employTypeId: Scalars['Int']['input'];
  employTypeName: Scalars['String']['input'];
  propertyId: Scalars['Int']['input'];
  propertyName: Scalars['String']['input'];
  roles?: InputMaybe<Array<EmployeeRolesUpdate>>;
};

export type EmployeeRolesUpdate = {
  deptCode?: InputMaybe<Scalars['String']['input']>;
  deptId: Scalars['Int']['input'];
  deptName: Scalars['String']['input'];
  roleId: Scalars['Int']['input'];
  roleName: Scalars['String']['input'];
  roleStatus: EmployeeStatus;
};

export type EmployeeWorkingHoursUpdate = {
  credits?: InputMaybe<Scalars['Float']['input']>;
  endTime: Scalars['String']['input'];
  roles?: InputMaybe<Array<InputMaybe<EmployeeWorkingHoursRolesUpdate>>>;
  startTime: Scalars['String']['input'];
  weekDays: Array<InputMaybe<WeekDay>>;
};

export type EmployeeWorkingHoursRolesUpdate = {
  roleId: Scalars['Int']['input'];
};

export type UpdateEmploymentType = {
  displayName?: InputMaybe<Scalars['String']['input']>;
  employmentCode?: InputMaybe<Scalars['String']['input']>;
  externalReference?: InputMaybe<Scalars['String']['input']>;
  hoursBetweenShifts?: InputMaybe<Scalars['Float']['input']>;
  maxHoursPerDay?: InputMaybe<Scalars['Float']['input']>;
  maxHoursPerWeek?: InputMaybe<Scalars['Float']['input']>;
  minHoursPerDay?: InputMaybe<Scalars['Float']['input']>;
  minHoursPerWeek?: InputMaybe<Scalars['Float']['input']>;
  organisationId?: InputMaybe<Scalars['Int']['input']>;
  organisationName?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateInvite = {
  emailAddr?: InputMaybe<Scalars['String']['input']>;
  employTypeId?: InputMaybe<Scalars['Int']['input']>;
  employTypeName?: InputMaybe<Scalars['String']['input']>;
  mobileNum?: InputMaybe<Scalars['String']['input']>;
  orgId?: InputMaybe<Scalars['Int']['input']>;
  orgName?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<InputMaybe<InviteInfoUpdate>>>;
  sendNow?: InputMaybe<Scalars['Boolean']['input']>;
  sendText?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  userExists?: InputMaybe<Scalars['Boolean']['input']>;
  userFirstName?: InputMaybe<Scalars['String']['input']>;
  userLastName?: InputMaybe<Scalars['String']['input']>;
};

export type JobEdit = {
  action?: InputMaybe<JobAction>;
  assignee?: InputMaybe<UserMinInput>;
  attachments?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  checklistItems?: InputMaybe<Array<InputMaybe<ChecklistItemInput>>>;
  checklistTemplates?: InputMaybe<Array<InputMaybe<ChecklistTemplateInput>>>;
  creditValue?: InputMaybe<Scalars['Float']['input']>;
  department?: InputMaybe<DepartmentMinInput>;
  doBy?: InputMaybe<Scalars['Int64']['input']>;
  durationMin?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isEscalated?: InputMaybe<Scalars['Boolean']['input']>;
  isRushed?: InputMaybe<Scalars['Boolean']['input']>;
  item?: InputMaybe<Scalars['String']['input']>;
  items?: InputMaybe<Array<InputMaybe<JobItemInfoInput>>>;
  locations?: InputMaybe<Array<InputMaybe<LocationMinInput>>>;
  metadata?: InputMaybe<JobMetadataEdit>;
  notes?: InputMaybe<Array<InputMaybe<JobNoteInput>>>;
  parentChecklistTaskId?: InputMaybe<Scalars['String']['input']>;
  predictiveDueTime?: InputMaybe<Scalars['Int64']['input']>;
  priority?: InputMaybe<JobPriority>;
  reporter?: InputMaybe<UserMinInput>;
  role?: InputMaybe<RoleMinInput>;
  scheduleStartTimeAt?: InputMaybe<Scalars['Int64']['input']>;
  status?: InputMaybe<JobStatus>;
  timeWindowEnd?: InputMaybe<Scalars['Int64']['input']>;
  timeWindowStart?: InputMaybe<Scalars['Int64']['input']>;
  type?: InputMaybe<JobType>;
};

export type JobMetadataEdit = {
  externalId?: InputMaybe<Scalars['String']['input']>;
  privateAttributes?: InputMaybe<Scalars['MetadataAttr']['input']>;
  publicAttributes?: InputMaybe<Scalars['MetadataAttr']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
};

export type JobNoteInput = {
  createdBy?: InputMaybe<UserMinInput>;
  text?: InputMaybe<Scalars['String']['input']>;
  time?: InputMaybe<Scalars['Int64']['input']>;
};

export type UpdateLocation = {
  description?: InputMaybe<Scalars['String']['input']>;
  isActive: Scalars['Boolean']['input'];
  isVirtual?: InputMaybe<Scalars['Boolean']['input']>;
  locationTypeId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['Int64']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
  roomTypeId?: InputMaybe<Scalars['ID']['input']>;
  sequence?: InputMaybe<Scalars['Float']['input']>;
  squareUnit?: InputMaybe<Scalars['Float']['input']>;
  tags?: InputMaybe<Array<InputMaybe<UpsertLocationTag>>>;
  virtualChildrenLocationIds?: InputMaybe<
    Array<InputMaybe<Scalars['Int64']['input']>>
  >;
};

export type UpdateLocationType = {
  code?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  squareUnit?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationUpdate = {
  code?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['Int']['input']>;
  products?: InputMaybe<Array<AccessProduct>>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type PropertyUpdate = {
  billingContact?: InputMaybe<PropertyContactUpdate>;
  billingLocation?: InputMaybe<PropertyLocationUpdate>;
  budgetValue?: InputMaybe<Scalars['Float']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  externalRefCode?: InputMaybe<Scalars['String']['input']>;
  internalRefCode?: InputMaybe<Scalars['String']['input']>;
  licensedRoomCount?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<PropertyLocationUpdate>;
  mgmtCompanyId?: InputMaybe<Scalars['Int']['input']>;
  mgmtCompanyName?: InputMaybe<Scalars['String']['input']>;
  operationsContact?: InputMaybe<PropertyContactUpdate>;
  orgId?: InputMaybe<Scalars['Int']['input']>;
  orgName?: InputMaybe<Scalars['String']['input']>;
  ownerId?: InputMaybe<Scalars['Int']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  products?: InputMaybe<Array<AccessProduct>>;
  regionalSettings?: InputMaybe<RegionalSettingsUpdate>;
  securitySettings?: InputMaybe<SecuritySettingsUpdate>;
  status?: InputMaybe<PropertyStatus>;
  useLocationOption?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PropertyContactUpdate = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type PropertyLocationUpdate = {
  address?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type RegionalSettingsUpdate = {
  language?: InputMaybe<Scalars['String']['input']>;
  logoURL?: InputMaybe<Scalars['String']['input']>;
  startOfFiscalYear?: InputMaybe<Scalars['String']['input']>;
  startOfWeek?: InputMaybe<Scalars['String']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
};

export type SecuritySettingsUpdate = {
  complexPassword?: InputMaybe<PasswordSettingsUpdate>;
  minUsernameChars?: InputMaybe<Scalars['Int']['input']>;
  selectLoginSettings?: InputMaybe<Scalars['Boolean']['input']>;
  simplePassword?: InputMaybe<PasswordSettingsUpdate>;
};

export type PasswordSettingsUpdate = {
  forcePasswordChange?: InputMaybe<Scalars['Int']['input']>;
  maskPassword?: InputMaybe<Scalars['Boolean']['input']>;
  minPasswordChars?: InputMaybe<Scalars['Int']['input']>;
  previousPasswordCnt?: InputMaybe<Scalars['Int']['input']>;
  reqAlphaChars?: InputMaybe<Scalars['Boolean']['input']>;
  reqNumbers?: InputMaybe<Scalars['Boolean']['input']>;
  reqSpecialChars?: InputMaybe<Scalars['Boolean']['input']>;
  sessionTimeout?: InputMaybe<Scalars['Int']['input']>;
  unsuccessfulAttempts?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateRole = {
  accessibleDepts?: InputMaybe<Array<InputMaybe<CreateAccessibleDepartments>>>;
  break?: InputMaybe<UpdateBreakRule>;
  departmentId?: InputMaybe<Scalars['Int']['input']>;
  departmentName?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  meals?: InputMaybe<UpdateBreakRule>;
  name?: InputMaybe<Scalars['String']['input']>;
  payment?: InputMaybe<UpdatePaymentOption>;
  pii?: InputMaybe<UpdatePiiRule>;
  productAccess?: InputMaybe<Array<InputMaybe<CreatePurchasedProduct>>>;
};

export type UpdateBreakRule = {
  duration?: InputMaybe<Scalars['Int']['input']>;
  frequency?: InputMaybe<Scalars['Int']['input']>;
  isRecurring?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdatePaymentOption = {
  aveRooms?: InputMaybe<Scalars['Int']['input']>;
  doubletime?: InputMaybe<Scalars['Float']['input']>;
  optionName?: InputMaybe<PaymentType>;
  overtime?: InputMaybe<Scalars['Float']['input']>;
  rate?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdatePiiRule = {
  address?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['Boolean']['input']>;
  firstName?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['Boolean']['input']>;
  mobile?: InputMaybe<Scalars['Boolean']['input']>;
  username?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateRoomType = {
  code?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  hkDefaultConfigs?: InputMaybe<
    Array<InputMaybe<RoomTypesHkDefaultConfigInput>>
  >;
  note?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateScheduleEntry = {
  isAllday?: InputMaybe<Scalars['Boolean']['input']>;
  isSplitShift?: InputMaybe<Scalars['Boolean']['input']>;
  splitStartSec?: InputMaybe<Scalars['Int']['input']>;
  splitStopSec?: InputMaybe<Scalars['Int']['input']>;
  timeStartSec?: InputMaybe<Scalars['Int']['input']>;
  timeStopSec?: InputMaybe<Scalars['Int']['input']>;
};

export type Subscription = {
  JobUpdated: Job;
  ScheduleEntriesProcessed: ScheduleEntriesProcessed;
  UserLoggedIn: SessionOnlineStatus;
};

export type SubscriptionJobUpdatedArgs = {
  authToken: Scalars['String']['input'];
  filters?: InputMaybe<Scalars['Map']['input']>;
  XOptiiUserInfo: Scalars['String']['input'];
};

export type SubscriptionScheduleEntriesProcessedArgs = {
  authToken: Scalars['String']['input'];
  XOptiiUserInfo: Scalars['String']['input'];
};

export type SubscriptionUserLoggedInArgs = {
  authToken: Scalars['String']['input'];
  sessionToken?: InputMaybe<Scalars['String']['input']>;
  XOptiiUserInfo: Scalars['String']['input'];
};

export type ScheduleEntriesProcessed = {
  action: Scalars['String']['output'];
  sourceScheduleId: Scalars['Int64']['output'];
  tagetScheduleId: Scalars['Int64']['output'];
};

export type JobDefaultInput = {
  action?: InputMaybe<Scalars['String']['input']>;
  department: DepartmentMinInput;
  item: Scalars['String']['input'];
  role?: InputMaybe<RoleMinInput>;
};

export type JobDefaultUpdate = {
  action?: InputMaybe<Scalars['String']['input']>;
  department?: InputMaybe<DepartmentMinInput>;
  item?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<RoleMinInput>;
};

export type RegisterDeviceError = {
  Detail: Scalars['String']['output'];
  Instance: Scalars['String']['output'];
  Status: Scalars['Int']['output'];
  Title: Scalars['String']['output'];
  Type: Scalars['String']['output'];
};

export type ListInvitesQueryVariables = Exact<{
  status?: InputMaybe<InviteStatus>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  deptId?: InputMaybe<Scalars['String']['input']>;
  employmentTypeId?: InputMaybe<Scalars['Int']['input']>;
  roleId?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ListInvitesQuery = {
  page: {
    totalCount: number;
    edges?: Array<{
      cursor: string;
      node: {
        userFirstName: string;
        userLastName: string;
        orgId: number;
        code: string;
        OrgName: string;
        userEmail: string;
        userPhone?: string | null;
        roles?: Array<{
          id: string;
          deptName: string;
          roleName: string;
          deptId: number;
          roleId: number;
        } | null> | null;
      };
    }> | null;
    pageInfo: { endCursor: string; hasNextPage: boolean };
  };
};

export type UpdateInviteMutationVariables = Exact<{
  inviteCode: Scalars['String']['input'];
  input: UpdateInvite;
}>;

export type UpdateInviteMutation = {
  updateInvite?: {
    id: string;
    code: string;
    userFirstName: string;
    userLastName: string;
  } | null;
};

export const ListInvitesDocument = gql`
  query ListInvites(
    $status: InviteStatus
    $cursor: String
    $first: Int
    $orderBy: String
    $deptId: String
    $employmentTypeId: Int
    $roleId: Int
  ) {
    page: ListInvites(
      filters: {
        deptId: $deptId
        employmentTypeId: $employmentTypeId
        roleId: $roleId
        status: $status
      }
      first: $first
      after: $cursor
      orderBy: $orderBy
    ) {
      totalCount
      edges {
        node {
          ... on Invite {
            userFirstName
            userLastName
            OrgName: orgName
            orgId
            userEmail: emailAddr
            userPhone: mobileNum
            code
            roles {
              id
              deptId: deptID
              deptName
              roleId: roleID
              roleName
            }
          }
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

/**
 * __useListInvitesQuery__
 *
 * To run a query within a React component, call `useListInvitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListInvitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListInvitesQuery({
 *   variables: {
 *      status: // value for 'status'
 *      cursor: // value for 'cursor'
 *      first: // value for 'first'
 *      orderBy: // value for 'orderBy'
 *      deptId: // value for 'deptId'
 *      employmentTypeId: // value for 'employmentTypeId'
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useListInvitesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListInvitesQuery,
    ListInvitesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListInvitesQuery, ListInvitesQueryVariables>(
    ListInvitesDocument,
    options,
  );
}
export function useListInvitesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListInvitesQuery,
    ListInvitesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListInvitesQuery, ListInvitesQueryVariables>(
    ListInvitesDocument,
    options,
  );
}
export function useListInvitesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListInvitesQuery,
    ListInvitesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ListInvitesQuery, ListInvitesQueryVariables>(
    ListInvitesDocument,
    options,
  );
}
export type ListInvitesQueryHookResult = ReturnType<typeof useListInvitesQuery>;
export type ListInvitesLazyQueryHookResult = ReturnType<
  typeof useListInvitesLazyQuery
>;
export type ListInvitesSuspenseQueryHookResult = ReturnType<
  typeof useListInvitesSuspenseQuery
>;
export type ListInvitesQueryResult = Apollo.QueryResult<
  ListInvitesQuery,
  ListInvitesQueryVariables
>;
export const UpdateInviteDocument = gql`
  mutation updateInvite($inviteCode: String!, $input: UpdateInvite!) {
    updateInvite(inviteCode: $inviteCode, input: $input) {
      id
      code
      userFirstName
      userLastName
    }
  }
`;
export type UpdateInviteMutationFn = Apollo.MutationFunction<
  UpdateInviteMutation,
  UpdateInviteMutationVariables
>;

/**
 * __useUpdateInviteMutation__
 *
 * To run a mutation, you first call `useUpdateInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInviteMutation, { data, loading, error }] = useUpdateInviteMutation({
 *   variables: {
 *      inviteCode: // value for 'inviteCode'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInviteMutation,
    UpdateInviteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateInviteMutation,
    UpdateInviteMutationVariables
  >(UpdateInviteDocument, options);
}
export type UpdateInviteMutationHookResult = ReturnType<
  typeof useUpdateInviteMutation
>;
export type UpdateInviteMutationResult =
  Apollo.MutationResult<UpdateInviteMutation>;
export type UpdateInviteMutationOptions = Apollo.BaseMutationOptions<
  UpdateInviteMutation,
  UpdateInviteMutationVariables
>;
