import React, { CSSProperties } from 'react';

import {
  COLORS,
  ConfigProvider,
  Layout,
  SPACING,
  ThemeConfig,
} from '@optii/ui-library';
import { Redirect, Route, Switch } from 'react-router-dom';

import { PERMISSIONS, useAccess } from '@optii/shared';
import { Header } from './components/header';
import { AppConfiguration } from './pages/appConfiguration';
import { UserManagement } from './pages/userManagement/index';
import { AppConfigurationForm } from './pages/appConfiguration/form';
import { JobsManagement } from './pages/JobsManagement';
import { ChecklistsManagement } from './pages/ChecklistsManagement';

const { Content } = Layout;

const LAYOUT_STYLE: CSSProperties = {
  minHeight: '100%',
};

const CONTENT_STYLE: CSSProperties = {
  height: '100%',
  paddingInline: SPACING.SIZE_MD,
  paddingBlock: SPACING.SIZE_XL,
};

const ABOVE_PROPERTY_THEME: ThemeConfig = {
  components: {
    Layout: {
      headerBg: COLORS.neutral[1],
      headerHeight: '102px',
      bodyBg: COLORS.neutral[2],
    },
  },
};

export function AboveProperty() {
  const { can } = useAccess();

  const canAccessJobManagement = can(
    PERMISSIONS.aboveProperty.above_prop_ctrl_view_job_management,
  );

  const canAccessChecklistManagement = can(
    PERMISSIONS.aboveProperty.above_prop_ctrl_view_checklist,
  );

  return (
    <ConfigProvider theme={ABOVE_PROPERTY_THEME}>
      <Layout style={LAYOUT_STYLE}>
        <Header />

        <Content style={CONTENT_STYLE}>
          <Switch>
            <Route
              exact
              path="/above-property/app-configuration"
              component={AppConfiguration}
            />

            <Route
              exact
              path="/above-property/app-configuration/create"
              component={AppConfigurationForm}
            />

            {/* <Route
              exact
              path="/above-property/app-configuration/:id"
              component={}
            /> */}
            <Route
              exact
              path="/above-property/user-management"
              component={UserManagement}
            />

            {canAccessJobManagement ? (
              <Route
                exact
                path="/above-property/jobs-management"
                component={JobsManagement}
              />
            ) : null}

            {canAccessChecklistManagement ? (
              <Route
                exact
                path="/above-property/checklists-management"
                component={ChecklistsManagement}
              />
            ) : null}

            <Route
              render={() => <Redirect to="/above-property/jobs-management" />}
            />
          </Switch>
        </Content>
      </Layout>
    </ConfigProvider>
  );
}
