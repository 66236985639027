import { SPACING, Spin } from '@optii/ui-library';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { ChecklistApp } from '@optii/checklists';
import { UserAccessContext } from '@optii/shared';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';
import {
  ArrayParam,
  BooleanParam,
  StringParam,
  useQueryParams,
} from 'use-query-params';
import {
  useGenerateEncryptedEmbedUrlQuery,
  useReportsQuery,
} from '../../api/reports';
import {
  SIGMA_EVENT_NAME,
  SIGMA_EVENTS,
  SUPPORTED_LANGUAGES,
} from '../utils/constants';
import { useOrganizationsQuery } from '../../api/organizations';

type Property = {
  display_name: string;
  property_id: number;
  org_id: number;
  parent_id: number;
  org_name: string;
  parent_org_display_name: string;
};

type TreeData = {
  title: string;
  value: string;
  children: {
    title: string;
    value: string;
    children: TreeData[];
  }[];
};

const SIGMA_ORIGIN = 'https://app.sigmacomputing.com';

function buildHierarchyWithChildren(
  parents: {
    label: string;
    value: string;
    title: string;
    parentId: string;
  }[],
  children: {
    value: string;
    title: string;
    pId: string;
    parentName: string;
  }[],
) {
  const lookup: { [key: string]: TreeData } = {};
  const hierarchy: TreeData[] = [];

  parents.forEach((parent) => {
    lookup[parent.value] = { ...parent, children: [] };
  });

  parents.forEach((parent) => {
    if (parent.parentId !== null && lookup[parent.parentId]) {
      lookup[parent.parentId].children.push(lookup[parent.value]);
    } else {
      hierarchy.push(lookup[parent.value]);
    }
  });

  children.forEach((child) => {
    if (child.pId && lookup[child.pId]) {
      lookup[child.pId].children.push({ ...child, children: [] });
    }
  });

  return hierarchy;
}

const Iframe = styled.iframe`
  border: ${SPACING.NONE};
  width: 100%;
  height: 81vh;
  * {
    overflow: hidden !important;
    & div:first-child {
      overflow: hidden !important;
    }
  }
`;

const sortTree = (tree: TreeData[], key: keyof TreeData): TreeData[] =>
  tree
    .map((item) => ({
      ...item,
      children: item.children ? sortTree(item.children, key) : [],
    }))
    .sort((a, b) => {
      if (a[key] > b[key]) {
        return 1;
      }
      if (a[key] < b[key]) {
        return -1;
      }
      return 0;
    });

export function ChecklistsManagement() {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [properties, setProperties] = useState<TreeData[]>([]);
  const [selectedProperties, setSelectedProperties] = useState<string[]>([]);
  const [existingAPChecklists, setExistingAPChecklists] = useState<string[]>(
    [],
  );
  const [mappedCreatorIds, setMappedCreatorIds] = useState<{
    [id: string]: string;
  }>({});
  const { user } = useContext(UserAccessContext.Context);
  const checklistManagementRef = useRef<HTMLIFrameElement>(null);
  const { data: organizationList, loading: organizationsLoading } =
    useOrganizationsQuery({});
  const { data } = useReportsQuery({
    variables: {
      filters: 'type==report,name==Checklists Management',
    },
  });
  const { data: src } = useGenerateEncryptedEmbedUrlQuery({
    variables: {
      embedKey: data?.reports.edges?.[0].node.embedKey,
      userEmail: user?.emailAddr,
      lng: SUPPORTED_LANGUAGES[
        user?.preferredLang as keyof typeof SUPPORTED_LANGUAGES
      ],
    },
    skip: !data?.reports.edges?.length,
    onError: (error) => {
      console.error(error);
    },
  });

  const [query, setQuery] = useQueryParams({
    edit: BooleanParam,
    openChecklistTemplate: StringParam,
    properties: ArrayParam,
    readOnlyChecklist: BooleanParam,
  });

  const organizationData = organizationList?.organizations;

  const formatPropertiesForHierarchy = useCallback(
    (values: Property[][]) => {
      const uniqueParentIds = values.flatMap((property) => {
        const [propertyData] = property;
        return propertyData.parent_id;
      });

      const uniqueOrgIds = values.flatMap((property) => {
        const [propertyData] = property;
        return propertyData.org_id;
      });

      const parentIds = [...new Set(uniqueOrgIds.concat(uniqueParentIds))];

      const uniqueParentObjects = parentIds
        .map((parent) => {
          const organization = organizationData?.find(
            (orgData) => orgData?.id === String(parent),
          );

          return {
            label: organization?.displayName || '',
            title: organization?.displayName || '',
            value: organization?.id || '',
            parentId: String(organization?.parentId) || '',
          };
        })
        .filter((item) => item.value);

      const formattedProperties = values.flatMap((property) => {
        const [propertyData] = property;

        const orgData = uniqueParentObjects.find(
          (item) => item.value === String(propertyData.org_id),
        );

        return {
          value: String(propertyData.property_id),
          title: propertyData.display_name,
          pId: orgData?.value || '',
          parentName: orgData?.label || '',
        };
      });

      const treeData = buildHierarchyWithChildren(
        uniqueParentObjects,
        formattedProperties,
      );

      return treeData;
    },
    [organizationData],
  );

  useEffect(() => {
    function onMessage(event: MessageEvent<any>) {
      const { data: sigmaData, source, origin } = event;
      if (event.data.type === 'workbook:loaded') {
        setLoading(false);
      }
      if (
        source === checklistManagementRef.current?.contentWindow &&
        origin === SIGMA_ORIGIN &&
        sigmaData.type === SIGMA_EVENTS.outbound
      ) {
        console.log('SIGMA_EVENT', sigmaData);
        if (sigmaData.name === SIGMA_EVENT_NAME.createChecklist) {
          const formattedProperties = formatPropertiesForHierarchy(
            sigmaData.values?.Shards,
          );

          const sortedProperties = sortTree(formattedProperties, 'title');

          setExistingAPChecklists(sigmaData.values.Checklists);
          setProperties(sortedProperties);
          setOpen(true);
        }

        if (
          sigmaData.name === SIGMA_EVENT_NAME.updateChecklist &&
          sigmaData.values
        ) {
          const formattedProperties = formatPropertiesForHierarchy(
            sigmaData.values?.Template?.[1].map((item: Property) => [item]),
          );

          const sortedProperties = sortTree(formattedProperties, 'title');

          setProperties(sortedProperties);
          setExistingAPChecklists(sigmaData.values.Checklists);

          const checklistTemplateId =
            sigmaData.values?.Template[0]?.checklisttemplate_id;
          const parentProperties = sigmaData.values?.Template[0]?.property_ids
            .split(',')
            .map((id: string) => id.trim());

          const mappedCreatorIdsToProperties = sigmaData.values?.Template[2];

          setSelectedProperties(parentProperties);

          setMappedCreatorIds(mappedCreatorIdsToProperties);
          setQuery({
            openChecklistTemplate: checklistTemplateId,
            readOnlyChecklist: true,
          });
          setOpen(true);
        }
      }
    }

    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [formatPropertiesForHierarchy, properties, setQuery]);

  return src?.url && !loading && !organizationsLoading ? (
    <>
      <Iframe
        title="Checklist Management"
        id="checklist-management-iframe"
        data-testid="checklist-management-iframe"
        src={src.url}
        seamless
        ref={checklistManagementRef}
      />
      {open ? (
        <ChecklistApp
          modalMode
          add={!query.openChecklistTemplate}
          setOpenModal={setOpen}
          isAboveProperty
          existingAPChecklists={existingAPChecklists}
          properties={properties}
          selectedProperties={selectedProperties}
          mappedCreatorIds={mappedCreatorIds}
        />
      ) : null}
    </>
  ) : (
    <Spin
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '70vh',
      }}
      spinning
      indicator={<LoadingOutlined spin />}
      size="large"
    />
  );
}
